<template>
  <v-breadcrumbs :items="items">
    <template v-slot:item="{ item }">
      <v-breadcrumbs-item
          :to="item.href"
          :disabled="item.disabled"
      >
        {{ item.text.toUpperCase() }}
      </v-breadcrumbs-item>
    </template>
    <div class="card_container" >
      <div class="card_header" >
        <div>การสะท้อนการเรียนรู้ วงรอบที่ 2 </div>
      </div>

      <v-breadcrumbs :items="items">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item
              :to="item.href"
              :disabled="item.disabled"
          >
            {{ item.text.toUpperCase() }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
      <div class="form_card" >
        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
        >
          <v-text-field
              v-model="name"
              :rules="[v => !!v || 'ชื่อจริง-นามสกุล']"
              label="ชื่อนักศึกษา"
              required
          ></v-text-field>

          <v-text-field
              v-model="school"
              :rules="[v => !!v || 'โรงเรียน']"
              label="โรงเรียน"
              required
          ></v-text-field>

          <v-text-field
              v-model="amphur"
              :rules="[v => !!v || 'อำเภอ']"
              label="อำเภอ"
              required
          ></v-text-field>

          <v-text-field
              v-model="province"
              :rules="[v => !!v || 'จังหวัด']"
              label="จังหวัด"
              required
          ></v-text-field>

          <v-row>
            <v-col
                cols="6"
                sm="5"
            >
            <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="date"
                    label="วันที่สะท้อนการจัดกิจกรรม"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                  v-model="date"
                  :active-picker.sync="activePicker"
                  @change="save"
              ></v-date-picker>
            </v-menu>
            </v-col>
            <v-col
                cols="6"
                sm="5"
            >
              <v-menu
                  ref="menu"
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="time"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="time"
                      label="เวลาที่สะท้อนกิจกรรม"
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                    v-if="menu2"
                    v-model="time"
                    full-width
                    format="24hr"
                    no-title
                    @click:minute="$refs.menu.save(time)"
                ></v-time-picker>
              </v-menu>
            </v-col>
          </v-row>

          <v-text-field
              v-model="class_detail"
              :rules="[v => !!v || 'นักเรียนชั้น']"
              label="นักเรียนชั้น"
              required
          ></v-text-field>

          <v-text-field
              v-model="student_amount"
              :rules="[v => !!v || 'จำนวน']"
              label="จำนวนนักเรียน"
              required
          ></v-text-field>

          <v-select
              v-model="course_select"
              :items="cours_list"
              item-text="label"
              item-value="id"
              :rules="[v => !!v || 'กรุณาเลือกรายวิชากลุ่มเรียนรู้']"
              label="รายวิชากลุ่มเรียนรู้"
              required
          ></v-select>

          <v-text-field
              v-model="people_amount"
              :rules="[v => !!v || 'จำนวน']"
              label="จำนวนผู้เข้าร่วม"
              required
          ></v-text-field>

          <div>
            <v-row v-if="people_amount !== 0" class="table_container" >
                <table class="custom_table" border="1" >
                  <tr>
                    <th>ลำดับ</th>
                    <th>ชื่อ-สกุล</th>
                    <th>ผู้ร่วม AAR</th>
                  </tr>
                  <tr v-for="index in parseInt(this.people_amount) " :key="index" >
                    <td>
                      {{ index }}
                    </td>
                    <td>
                      <v-select
                          v-model="people_select[index]"
                          :items="people_list"
                          item-text="label"
                          item-value="id"
                          :rules="[v => !!v || 'กรุณาเลือกรายวิชากลุ่มเรียนรู้']"
                          label="ผู้เข้าร่วม"
                          required
                      ></v-select>
                    </td>
                    <td>
                      <v-select
                          v-model="people_type_select[index]"
                          :items="people_type"
                          item-text="label"
                          item-value="id"
                          :rules="[v => !!v || 'กรุณาเลือกรายวิชากลุ่มเรียนรู้']"
                          label="รายวิชากลุ่มเรียนรู้"
                          required
                      ></v-select>
                    </td>
                  </tr>
                </table>
            </v-row>
          </div>

          <h3 class="mb-5" >ประเด็นนำสะท้อนกิจกรรมการเรียนรู้ก่อนนำไปใช้สอน</h3>


          <v-textarea
              outlined
              label="1. สิ่งที่ครูผู้สอนทำได้ดีและควรรักษาไว้ให้มีต่อไป "
              :value="well_done"
              :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
          ></v-textarea>

          <v-textarea
              outlined
              label="2. สิ่งที่เป็นปัญหาและอุปสรรคที่ทำให้การจัดกิจกรรมการเรียนรู้ในครั้งนี้ไม่เป็นไปตามเป้าหมาย"
              :value="not_on_target"
              :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
          ></v-textarea>


          <v-textarea
              outlined
              label="3. สิ่งที่ครูต้องปรับให้ดีขึ้นเพื่อพัฒนาการเรียนรู้ของนักเรียนมีประเด็นใดบ้างและจะทำอย่างไร"
              :value="improve"
              :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
          ></v-textarea>

          <v-textarea
              outlined
              label="4. นักเรียนบรรลุวัตถุประสงค์การเรียนรู้ในครั้งนี้จำนวนกี่คน"
              :value="student_achieve"
              :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
          ></v-textarea>

          <v-textarea
              outlined
              label="5. วิธีการแก้ปัญหาที่นำมาใช้ในการจัดกิจกรรมการเรียนรู้เกิดผลอย่างไร"
              :value="howto_fix"
              :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
          ></v-textarea>

          <v-text-field
              v-model="people_amount"
              :rules="[v => !!v || 'จำนวนชั่วโมง']"
              label="สรุปเวลา/ชั่วโมง"
              required
          ></v-text-field>

          <hr>




          <div align="center" style="margin-top: 2rem" >
            <v-btn
                color="success"
                class="mr-4"
                @click="validate"
            >
              อัพเดท
            </v-btn>

            <v-btn
                color="error"
                class="mr-4"
                @click="reset"
            >
             ล้างค่า
            </v-btn>

            <v-btn
                color="success"
                class="mr-4"
                @click="print_page"
            >
              ปริ้น
            </v-btn>


          </div>

        </v-form>
      </div>

    </div>
  </v-breadcrumbs>



</template>

<script>
import {GetDepartment, GetMachine, UserRegister} from "../../lib/controller";
import {baseUrl} from "../../const/api";
import $ from 'jquery'

export default {
  name: "Users",
  data: () => ({
    rules: [
      value => !value || value.size < 2000000 || 'Avatar size should be less than 2 MB!',
    ],
    machine_item:{},
    machine_id:0,
    valid: true,
    pincode:"",
    file:"",
    show1: false,
    show4: false,
    is_admin:false,
    first_name: '',
    last_name: '',
    user_name: '',
    password: '',
    password_confirm: '',
    email: '',
    rfidcode: '',

    email_rules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],


    department: '',
    nameRules: [
      v => !!v || 'Name is required',
      v => (v && v.length <= 10) || 'Name must be less than 10 characters',
    ],



    select: null,
    itemss:[],
    checkbox: false,

    baseUrl:baseUrl,
    items: [
      {
        text: 'หน้าหลัก',
        disabled: false,
        href: '/',
      },
      {
        text: 'การสะท้อนการเรียนรู้ วงรอบที่ 2',
        disabled: true,
        href: '/aar_2',
      },
    ],
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: 'รูปโปรไฟล์',
        align: 'start',
        sortable: false,
        value: 'image',
      },
      { text: 'ชื่อ', value: 'first_name' },
      { text: 'นามสกุล', value: 'last_name' },
      { text: 'แผนก', value: 'departments[0].title' },
      { text: 'RFID', value: 'rfidcode' },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
    desserts: [],
    editedIndex: -1,
    resultData:[],

    activePicker: null,
    date: null,
    menu: false,

    name:"",
    school:"",
    amphur:"",
    province:"",
    class_detail:"",
    student_amount:"",
    course_select:1,
    people_amount:0,

    well_done:"",
    not_on_target:"",
    improve:"",
    student_achieve:"",
    howto_fix:"",

    cours_list:[
      {
        "label":"สังคมศึกษาศาสนาและวัฒนธรรม",
        "id":1
      },
      {
        "label":"ภาษาไทย",
        "id":2
      },
      {
        "label":"คอมพิวเตอร์ศึกษา",
        "id":3
      }
    ],
    people_select:[],
    people_type_select:[],
    people_list:[
      {
        "label":"ผศ.ดร.อรัญ ซุยกระเดื่อง",
        "id":1
      },
      {
        "label":"นายจักรพันธ์ แก้วกัณหา",
        "id":2
      },
      {
        "label":"นางสาววราภรณ์ สุภาเฮือง",
        "id":3
      }
    ],
    people_type:[
      {
        "label":"อาจารย์นิเทศ",
        "id":1
      },
      {
        "label":"ครูพี่เลี้ยง",
        "id":2
      },
      {
        "label":"นักศึกษา",
        "id":3
      }
    ],

    time: null,
    menu2: false,
    modal2: false,
  }),

  computed: {

  },
  watch: {
    menu (val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
  },

  created () {
    this.initialize()
    this.GetDepartment()
    this.GetMachines()
  },
  methods: {
    print_page(){
      window.open('./aar_print', '_blank');
    },
    save (date) {
      this.$refs.menu.save(date)
    },
    initialize () {

    },
    async GetMachines(){
      let machine = [];
      const { status , results } = await GetMachine();
      if(status === 200){

        results.data.forEach(function (result){
          console.log(result)
          machine.push({
            "label":result.title,
            "id":result.machine_number,
          })
        });
      }

      this.machine_item = machine;
      console.log(this.itemss)

    },
    validate () {
      console.log(this.file)
      if(this.$refs.form.validate()){
        this.UserRegister();
      }
    },
    reset () {
      this.$refs.form.reset()
      $('#image_preview').attr('src', '');
    },
    async UserRegister(){
      let Swal = this.$swal;

      let formData = new FormData();
      formData.append("first_name",this.first_name)
      formData.append("last_name",this.last_name)
      formData.append("user_name",this.user_name)
      formData.append("password",this.password)
      formData.append("email",this.email)
      formData.append("rfidcode",this.rfidcode)
      formData.append("pincode",this.pincode)
      formData.append("is_admin",this.is_admin ? 1 : 0)
      formData.append("department_id",this.department)
      formData.append("file",this.file)
      formData.append("machine_id",this.machine_id)


      const { status , results } = await UserRegister(formData,this.machine_id);
      if(status === 200){
        Swal({
          icon: 'success',
          title: 'Success',
        })
        this.$refs.form.reset()
        $('#image_preview').attr('src', '');
        console.log(results)
      }else{

        Swal({
          icon: results.icon,
          title: results.message,
        })

      }

    },

    async GetDepartment(){
      let department = [];
      const { status , results } = await GetDepartment();
      if(status === 200){

        results.data.forEach(function (result,index){
          console.log(index)
          department.push({
            "label":result.title,
            "id":result.department_id,
          })
        });
      }

      this.itemss = department;
      console.log(this.itemss)

    },
    Preview_image(e) {
      if (e) {
        this.file = e;
        $('#image_preview').attr('src', URL.createObjectURL(e)); // jQuery selector
      }
    },
  },
}


</script>

<style scoped>
.card_container{
  width: 100%;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 1px solid #EBEDF3;
  border-radius: 0.42rem;
}
.card_header{
  padding: 1.5rem 2rem;
  margin-bottom: 0;
  background-color: #ffffff;
  border-bottom: 1px solid #EBEDF3;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.form_card{
  padding:2rem;
}
.image_preview_container{
  display: flex;
  justify-content: center;
  align-items: center;
}
#image_preview{
  width: 20rem;
  text-align: center;
}
.custom_table{
  border-collapse: collapse;
}
.table_container{
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}
</style>
