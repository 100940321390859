import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'


import AAR1 from '../views/AAR/AAR1'
import AAR_Print from '../views/AAR/AAR_Print'

import AAR2 from '../views/AAR/AAR2'
import AAR3 from '../views/AAR/AAR3'
import PLC from '../views/plc/plc'
import PLC2 from '../views/plc/plc2'
import PLC_Print from '../views/plc/PLC_Print'
import plan_1 from '../views/plan/plan_1'
import plan_history from '../views/plan/plan_history'
import plan_requirement from '../views/plan/plan_requirement'
import plan_detail from '../views/plan/plan_detail'
import Plan_Print from '../views/plan/Plan_Print'
import Plan_Print_History from '../views/plan/Plan_Print_History'
import introduction from '../views/portfolio/introduction'
import profile from '../views/portfolio/profile'
import cer from '../views/portfolio/cer'
import course from '../views/portfolio/course'
import work from '../views/portfolio/work'
import workCommunity from '../views/portfolio/workCommunity'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/aar_1',
    name: 'AAR1',
    component: AAR1
  },
  {
    path: '/aar_print',
    name: 'AAR_Print',
    component: AAR_Print
  },
  {
    path: '/aar_2',
    name: 'AAR2',
    component: AAR2
  },
  {
    path: '/aar_3',
    name: 'AAR3',
    component: AAR3
  },
  {
    path: '/plc',
    name: 'plc',
    component: PLC
  },
  {
    path: '/plc2',
    name: 'plc2',
    component: PLC2
  },
  {
    path: '/plc_print',
    name: 'PLC_Print',
    component: PLC_Print
  },
  {
    path: '/plan_1',
    name: 'plan_1',
    component: plan_1
  },
  {
    path: '/plan_history',
    name: 'plan_history',
    component: plan_history
  },
  {
    path: '/plan_requirement',
    name: 'plan_requirement',
    component: plan_requirement
  },
  {
    path: '/plan_detail',
    name: 'plan_detail',
    component: plan_detail
  },
  {
    path: '/plan_print',
    name: 'Plan_Print',
    component: Plan_Print
  },
  {
    path: '/plan_print_history',
    name: 'Plan_Print_History',
    component: Plan_Print_History
  },
  {
    path: '/introduction',
    name: 'introduction',
    component: introduction
  },
  {
    path: '/profile',
    name: 'profile',
    component: profile
  },
  {
    path: '/cer',
    name: 'cer',
    component: cer
  },
  {
    path: '/course',
    name: 'course',
    component: course
  },
  {
    path: '/work',
    name: 'work',
    component: work
  },
  {
    path: '/work_community',
    name: 'workCommunity',
    component: workCommunity
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
