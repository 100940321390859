<template>
  <div>
    <div class="page_print">
      <div align="center" >
        แลกเปลี่ยนเรียนรู้ร่วมกันในรูปแบบชุมชนแห่งการเรียนรู้ (PLC) พัฒนา : Creative Discussion (แนวทางปฏิบัติและพัฒนาตนเอง)
      </div><br>

      <div class="row_print_des">
        1. หลักการและเหตุผล <br>
        <p class="description">
          Lorem Ipsum คือ เนื้อหาจำลองแบบเรียบๆ ที่ใช้กันในธุรกิจงานพิมพ์หรืองานเรียงพิมพ์ มันได้กลายมาเป็นเนื้อหาจำลองมาตรฐานของธุรกิจดังกล่าวมาตั้งแต่ศตวรรษที่ 16 เมื่อเครื่องพิมพ์โนเนมเครื่องหนึ่งนำรางตัวพิมพ์มาสลับสับตำแหน่งตัวอักษรเพื่อทำหนังสือตัวอย่าง
        </p><br>
      </div>

      <div class="row_print_des">
        2.วัตถุประสงค์ <br>
        <p class="description">
          Lorem Ipsum คือ เนื้อหาจำลองแบบเรียบๆ ที่ใช้กันในธุรกิจงานพิมพ์หรืองานเรียงพิมพ์ มันได้กลายมาเป็นเนื้อหาจำลองมาตรฐานของธุรกิจดังกล่าวมาตั้งแต่ศตวรรษที่ 16 เมื่อเครื่องพิมพ์โนเนมเครื่องหนึ่งนำรางตัวพิมพ์มาสลับสับตำแหน่งตัวอักษรเพื่อทำหนังสือตัวอย่าง
        </p><br>
      </div>

      <div class="row_print_des">
        3. วิธีการดำเนินงาน  <br>
        &nbsp;&nbsp;&nbsp; 3.1 แนวทางการปฏิบัติกิจกรรมการสร้างชุมชนการเรียนรู้ทางวิชาชีพ (PLC) ดังนี้ <br>
        <p class="description">
          Lorem Ipsum คือ เนื้อหาจำลองแบบเรียบๆ ที่ใช้กันในธุรกิจงานพิมพ์หรืองานเรียงพิมพ์ มันได้กลายมาเป็นเนื้อหาจำลองมาตรฐานของธุรกิจดังกล่าวมาตั้งแต่ศตวรรษที่ 16 เมื่อเครื่องพิมพ์โนเนมเครื่องหนึ่งนำรางตัวพิมพ์มาสลับสับตำแหน่งตัวอักษรเพื่อทำหนังสือตัวอย่าง
        </p><br>
        &nbsp;&nbsp;&nbsp; 3.2 กระบวนการของ PLC<br>
        <p class="description">
          Lorem Ipsum คือ เนื้อหาจำลองแบบเรียบๆ ที่ใช้กันในธุรกิจงานพิมพ์หรืองานเรียงพิมพ์ มันได้กลายมาเป็นเนื้อหาจำลองมาตรฐานของธุรกิจดังกล่าวมาตั้งแต่ศตวรรษที่ 16 เมื่อเครื่องพิมพ์โนเนมเครื่องหนึ่งนำรางตัวพิมพ์มาสลับสับตำแหน่งตัวอักษรเพื่อทำหนังสือตัวอย่าง
        </p><br>
        &nbsp;&nbsp;&nbsp; 3.3 บทบาทหน้าที่ของสมาชิกกลุ่มตามกระบวนการ PLC<br>
        <p class="description">
          Lorem Ipsum คือ เนื้อหาจำลองแบบเรียบๆ ที่ใช้กันในธุรกิจงานพิมพ์หรืองานเรียงพิมพ์ มันได้กลายมาเป็นเนื้อหาจำลองมาตรฐานของธุรกิจดังกล่าวมาตั้งแต่ศตวรรษที่ 16 เมื่อเครื่องพิมพ์โนเนมเครื่องหนึ่งนำรางตัวพิมพ์มาสลับสับตำแหน่งตัวอักษรเพื่อทำหนังสือตัวอย่าง
        </p><br>
        &nbsp;&nbsp;&nbsp; 3.4. วัน เวลา สถานที่ ในการดำเนินงาน<br>
        <p class="description">
          Lorem Ipsum คือ เนื้อหาจำลองแบบเรียบๆ ที่ใช้กันในธุรกิจงานพิมพ์หรืองานเรียงพิมพ์ มันได้กลายมาเป็นเนื้อหาจำลองมาตรฐานของธุรกิจดังกล่าวมาตั้งแต่ศตวรรษที่ 16 เมื่อเครื่องพิมพ์โนเนมเครื่องหนึ่งนำรางตัวพิมพ์มาสลับสับตำแหน่งตัวอักษรเพื่อทำหนังสือตัวอย่าง
        </p><br>
      </div>


    </div>
    <div class="page_print">
      <div class="row_print_des">
        &nbsp;&nbsp;&nbsp; 3.5. สรุปผลการดำเนินงาน<br>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 3.5.1 ประเด็นด้านผู้เรียน<br>
        <p class="description">
          Lorem Ipsum คือ เนื้อหาจำลองแบบเรียบๆ ที่ใช้กันในธุรกิจงานพิมพ์หรืองานเรียงพิมพ์ มันได้กลายมาเป็นเนื้อหาจำลองมาตรฐานของธุรกิจดังกล่าวมาตั้งแต่ศตวรรษที่ 16 เมื่อเครื่องพิมพ์โนเนมเครื่องหนึ่งนำรางตัวพิมพ์มาสลับสับตำแหน่งตัวอักษรเพื่อทำหนังสือตัวอย่าง
        </p><br>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 3.5.2 ประเด็นด้านกิจกรรม<br>
        <p class="description">
          Lorem Ipsum คือ เนื้อหาจำลองแบบเรียบๆ ที่ใช้กันในธุรกิจงานพิมพ์หรืองานเรียงพิมพ์ มันได้กลายมาเป็นเนื้อหาจำลองมาตรฐานของธุรกิจดังกล่าวมาตั้งแต่ศตวรรษที่ 16 เมื่อเครื่องพิมพ์โนเนมเครื่องหนึ่งนำรางตัวพิมพ์มาสลับสับตำแหน่งตัวอักษรเพื่อทำหนังสือตัวอย่าง
        </p><br>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 3.5.3 ประเด็นด้านครู<br>
        <p class="description">
          Lorem Ipsum คือ เนื้อหาจำลองแบบเรียบๆ ที่ใช้กันในธุรกิจงานพิมพ์หรืองานเรียงพิมพ์ มันได้กลายมาเป็นเนื้อหาจำลองมาตรฐานของธุรกิจดังกล่าวมาตั้งแต่ศตวรรษที่ 16 เมื่อเครื่องพิมพ์โนเนมเครื่องหนึ่งนำรางตัวพิมพ์มาสลับสับตำแหน่งตัวอักษรเพื่อทำหนังสือตัวอย่าง
        </p><br>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 3.5.4 ประเด็นสื่อการสอน<br>
        <p class="description">
          Lorem Ipsum คือ เนื้อหาจำลองแบบเรียบๆ ที่ใช้กันในธุรกิจงานพิมพ์หรืองานเรียงพิมพ์ มันได้กลายมาเป็นเนื้อหาจำลองมาตรฐานของธุรกิจดังกล่าวมาตั้งแต่ศตวรรษที่ 16 เมื่อเครื่องพิมพ์โนเนมเครื่องหนึ่งนำรางตัวพิมพ์มาสลับสับตำแหน่งตัวอักษรเพื่อทำหนังสือตัวอย่าง
        </p><br>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 3.5.5 ประเด็นด้านบรรยากาศ<br>
        <p class="description">
          Lorem Ipsum คือ เนื้อหาจำลองแบบเรียบๆ ที่ใช้กันในธุรกิจงานพิมพ์หรืองานเรียงพิมพ์ มันได้กลายมาเป็นเนื้อหาจำลองมาตรฐานของธุรกิจดังกล่าวมาตั้งแต่ศตวรรษที่ 16 เมื่อเครื่องพิมพ์โนเนมเครื่องหนึ่งนำรางตัวพิมพ์มาสลับสับตำแหน่งตัวอักษรเพื่อทำหนังสือตัวอย่าง
        </p><br>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 3.5.5 ประเด็นด้านบรรยากาศ<br>
        <p class="description">
          Lorem Ipsum คือ เนื้อหาจำลองแบบเรียบๆ ที่ใช้กันในธุรกิจงานพิมพ์หรืองานเรียงพิมพ์ มันได้กลายมาเป็นเนื้อหาจำลองมาตรฐานของธุรกิจดังกล่าวมาตั้งแต่ศตวรรษที่ 16 เมื่อเครื่องพิมพ์โนเนมเครื่องหนึ่งนำรางตัวพิมพ์มาสลับสับตำแหน่งตัวอักษรเพื่อทำหนังสือตัวอย่าง
        </p><br>
      </div>

    </div>
    <div class="page_print">
      <div class="row_print_des">
        &nbsp;&nbsp;&nbsp; 3.6 อภิปรายผลการดำเนินงาน <br>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 3.6.1 ผลลัพธ์ที่เกิดจากกระบวนการ ดังนี้<br>
        <p class="description">
          Lorem Ipsum คือ เนื้อหาจำลองแบบเรียบๆ ที่ใช้กันในธุรกิจงานพิมพ์หรืองานเรียงพิมพ์ มันได้กลายมาเป็นเนื้อหาจำลองมาตรฐานของธุรกิจดังกล่าวมาตั้งแต่ศตวรรษที่ 16 เมื่อเครื่องพิมพ์โนเนมเครื่องหนึ่งนำรางตัวพิมพ์มาสลับสับตำแหน่งตัวอักษรเพื่อทำหนังสือตัวอย่าง
        </p><br>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 3.6.2 ผลลัพธ์ที่เกิดกับผู้เรียน / ครู / สมาชิกที่เข้าร่วมเครือข่าย PLC<br>
        <p class="description">
          Lorem Ipsum คือ เนื้อหาจำลองแบบเรียบๆ ที่ใช้กันในธุรกิจงานพิมพ์หรืองานเรียงพิมพ์ มันได้กลายมาเป็นเนื้อหาจำลองมาตรฐานของธุรกิจดังกล่าวมาตั้งแต่ศตวรรษที่ 16 เมื่อเครื่องพิมพ์โนเนมเครื่องหนึ่งนำรางตัวพิมพ์มาสลับสับตำแหน่งตัวอักษรเพื่อทำหนังสือตัวอย่าง
        </p><br>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 3.6.3 คุณค่าที่เกิดต่อวงการศึกษา<br>
        <p class="description">
          Lorem Ipsum คือ เนื้อหาจำลองแบบเรียบๆ ที่ใช้กันในธุรกิจงานพิมพ์หรืองานเรียงพิมพ์ มันได้กลายมาเป็นเนื้อหาจำลองมาตรฐานของธุรกิจดังกล่าวมาตั้งแต่ศตวรรษที่ 16 เมื่อเครื่องพิมพ์โนเนมเครื่องหนึ่งนำรางตัวพิมพ์มาสลับสับตำแหน่งตัวอักษรเพื่อทำหนังสือตัวอย่าง
        </p><br>
        &nbsp;&nbsp;&nbsp;3.7 ผลที่เกิดจากการดำเนินงาน<br>
        <p class="description">
          Lorem Ipsum คือ เนื้อหาจำลองแบบเรียบๆ ที่ใช้กันในธุรกิจงานพิมพ์หรืองานเรียงพิมพ์ มันได้กลายมาเป็นเนื้อหาจำลองมาตรฐานของธุรกิจดังกล่าวมาตั้งแต่ศตวรรษที่ 16 เมื่อเครื่องพิมพ์โนเนมเครื่องหนึ่งนำรางตัวพิมพ์มาสลับสับตำแหน่งตัวอักษรเพื่อทำหนังสือตัวอย่าง
        </p><br>
        &nbsp;&nbsp;&nbsp;3.8 ร่องรอย/หลักฐาน<br>
        <p class="description">
          Lorem Ipsum คือ เนื้อหาจำลองแบบเรียบๆ ที่ใช้กันในธุรกิจงานพิมพ์หรืองานเรียงพิมพ์ มันได้กลายมาเป็นเนื้อหาจำลองมาตรฐานของธุรกิจดังกล่าวมาตั้งแต่ศตวรรษที่ 16 เมื่อเครื่องพิมพ์โนเนมเครื่องหนึ่งนำรางตัวพิมพ์มาสลับสับตำแหน่งตัวอักษรเพื่อทำหนังสือตัวอย่าง
        </p><br>
        &nbsp;&nbsp;&nbsp;3.9 บทเรียนที่ได้จากการดำเนินงาน<br>
        <p class="description">
          Lorem Ipsum คือ เนื้อหาจำลองแบบเรียบๆ ที่ใช้กันในธุรกิจงานพิมพ์หรืองานเรียงพิมพ์ มันได้กลายมาเป็นเนื้อหาจำลองมาตรฐานของธุรกิจดังกล่าวมาตั้งแต่ศตวรรษที่ 16 เมื่อเครื่องพิมพ์โนเนมเครื่องหนึ่งนำรางตัวพิมพ์มาสลับสับตำแหน่งตัวอักษรเพื่อทำหนังสือตัวอย่าง
        </p><br>
        &nbsp;&nbsp;&nbsp;3.10 สิ่งที่จะดำเนินการต่อไป<br>
        <p class="description">
          Lorem Ipsum คือ เนื้อหาจำลองแบบเรียบๆ ที่ใช้กันในธุรกิจงานพิมพ์หรืองานเรียงพิมพ์ มันได้กลายมาเป็นเนื้อหาจำลองมาตรฐานของธุรกิจดังกล่าวมาตั้งแต่ศตวรรษที่ 16 เมื่อเครื่องพิมพ์โนเนมเครื่องหนึ่งนำรางตัวพิมพ์มาสลับสับตำแหน่งตัวอักษรเพื่อทำหนังสือตัวอย่าง
        </p><br>
        &nbsp;&nbsp;&nbsp;3.11 ปัญหา /อุปสรรค<br>
        <p class="description">
          Lorem Ipsum คือ เนื้อหาจำลองแบบเรียบๆ ที่ใช้กันในธุรกิจงานพิมพ์หรืองานเรียงพิมพ์ มันได้กลายมาเป็นเนื้อหาจำลองมาตรฐานของธุรกิจดังกล่าวมาตั้งแต่ศตวรรษที่ 16 เมื่อเครื่องพิมพ์โนเนมเครื่องหนึ่งนำรางตัวพิมพ์มาสลับสับตำแหน่งตัวอักษรเพื่อทำหนังสือตัวอย่าง
        </p><br>
        &nbsp;&nbsp;&nbsp;3.12 ข้อเสนอแนะ<br>
        <p class="description">
          Lorem Ipsum คือ เนื้อหาจำลองแบบเรียบๆ ที่ใช้กันในธุรกิจงานพิมพ์หรืองานเรียงพิมพ์ มันได้กลายมาเป็นเนื้อหาจำลองมาตรฐานของธุรกิจดังกล่าวมาตั้งแต่ศตวรรษที่ 16 เมื่อเครื่องพิมพ์โนเนมเครื่องหนึ่งนำรางตัวพิมพ์มาสลับสับตำแหน่งตัวอักษรเพื่อทำหนังสือตัวอย่าง
        </p><br>
      </div>

    </div>

    <div class="page_print">
      <div align="center" >
        แผนปฏิบัติการชุมชนแห่งการเรียนรู้ทางวิชาชีพ (PLC Action Plan)<br>
        แผนการดำเนินงาน ภาคเรียนที่ 1 ปีการศึกษา 2566
      </div><br>

      <div class="row_print">
        1. ชื่อ-สกุล Model Teacher
        <div style="width: 463px;text-align: center" class="horizontal_dotted_line">นางสาววราภรณ์ สุภาเฮือง</div>
        เจ้าของแผน
      </div>
      <div class="row_print">
        2. โรงเรียน
        <div style="width: 160px;text-align: center" class="horizontal_dotted_line">นครขอนแก่น</div>
        สังกัด<div style="width: 450px;text-align: center" class="horizontal_dotted_line">สำนักงานเขตพื้นที่การศึกษามัธยมศึกษา เขต 26</div>
      </div>
      <div class="row_print">
       จังหวัด
        <div style="width: 160px;text-align: center" class="horizontal_dotted_line">ขอนแก่น</div>
      </div>
      <div class="row_print">
        3. รายวิชาที่ดำเนินการ
        <div style="width: 160px;text-align: center" class="horizontal_dotted_line">สังคมศึกษา</div>
        กลุ่มสาระการเรียนรู้<div style="width: 282px;text-align: center" class="horizontal_dotted_line">สังคมศึกษาศาสนาและวัฒนธรรม</div>
      </div>
      <div class="row_print">
        4. นักเรียนชั้น / ห้อง
        <div style="width: 160px;text-align: center" class="horizontal_dotted_line">มัธยมศึกษาปีที่ 4</div>
        นักเรียนชาย<div style="width: 100px;text-align: center" class="horizontal_dotted_line">40</div>คน
      </div>
      <div class="row_print" style="margin-top: 0" >
        นักเรียนหญิง<div style="width: 100px;text-align: center" class="horizontal_dotted_line">40</div>คน
        รวม<div style="width: 100px;text-align: center" class="horizontal_dotted_line">80</div>คน
      </div>
      5. สมาชิกทีม PLT ประเภทเพื่อนครู (Buddy Teachers :BT)
      <div class="row_print">
       &nbsp;&nbsp;&nbsp;&nbsp; 5.1 เพื่อนครู
        <div style="width: 160px;text-align: center" class="horizontal_dotted_line">นางดวงเพ็ญ อธิแสน </div>
        วิชาเอก<div style="width: 125px;text-align: center" class="horizontal_dotted_line">สังคมศึกษา</div>
        อีเมล<div style="width: 246px;text-align: center" class="horizontal_dotted_line">kruduangpen@gmail.com</div>
      </div>

      6. สมาชิกทีม PLT ประเภทผู้บริหาร (School Administratos :SA)
      <div class="row_print" style="margin-top: 0" >
        &nbsp;&nbsp;&nbsp;&nbsp; 6.1 ผู้อำนวยการโรงเรียน
        <div style="width: 240px;text-align: center" class="horizontal_dotted_line">นางปราณี วัตนธรรม </div>
        วิทยฐานะ<div style="width: 231px;text-align: center" class="horizontal_dotted_line">ผู้อำนวยการชำนาญการ</div>
      </div>
      <div class="row_print" style="margin-top: 0" >
        &nbsp;&nbsp;&nbsp;&nbsp;วิชาเอก<div style="width: 246px;text-align: center" class="horizontal_dotted_line">บริหารการศึกษา</div>
      </div>

      <div class="row_print" style="margin-top: 0" >
        &nbsp;&nbsp;&nbsp;&nbsp; 6.2 รองผู้อำนวยการโรงเรียน
        <div style="width: 240px;text-align: center" class="horizontal_dotted_line">นางสาวจันทร์เพ็ญ ทองดวง </div>
        วิทยฐานะ<div style="width: 207px;text-align: center" class="horizontal_dotted_line">รองผู้อำนวยการชำนาญการ</div>
      </div>
      <div class="row_print" style="margin-top: 0" >
        &nbsp;&nbsp;&nbsp;&nbsp;วิชาเอก<div style="width: 246px;text-align: center" class="horizontal_dotted_line">บริหารการศึกษา</div>
      </div>

      <div class="row_print" style="margin-top: 0" >
        &nbsp;&nbsp;&nbsp;&nbsp; 6.3 หัวหน้ากลุ่มสาระ
        <div style="width: 240px;text-align: center" class="horizontal_dotted_line">นางนุชรินทร์ ปินะกาตาโพธ์ </div>
        วิทยฐานะ<div style="width: 207px;text-align: center" class="horizontal_dotted_line">ครูชำนาญการพิเศษ</div>
      </div>
      <div class="row_print" style="margin-top: 0" >
        &nbsp;&nbsp;&nbsp;&nbsp;วิชาเอก<div style="width: 246px;text-align: center" class="horizontal_dotted_line">ภาษาอังกฤษ</div>
      </div>

      7. สมาชิกทีม PLT ประเภทผู้ทรงคุณวุฒิทางการศึกษา (Seniore Educator :SE)
      <div class="row_print" style="margin-top: 0" >
        &nbsp;&nbsp;&nbsp;&nbsp; 7.1
        <div style="width: 240px;text-align: center" class="horizontal_dotted_line"> </div>
        วิทยฐานะ/ตำแหน่งทางวิชาการ<div style="width: 231px;text-align: center" class="horizontal_dotted_line"></div>
      </div>

      <div class="row_print" style="margin-top: 0" >
        &nbsp;&nbsp;&nbsp;&nbsp; 7.2
        <div style="width: 240px;text-align: center" class="horizontal_dotted_line"> </div>
        วิทยฐานะ/ตำแหน่งทางวิชาการ<div style="width: 231px;text-align: center" class="horizontal_dotted_line"></div>
      </div>


    </div>
  </div>
</template>

<script>
import {GetDepartment, GetMachine, UserRegister} from "../../lib/controller";
import {baseUrl} from "../../const/api";
import $ from 'jquery'

export default {
  name: "Users",
  components: {},
  data: () => ({
    rules: [
      value => !value || value.size < 2000000 || 'Avatar size should be less than 2 MB!',
    ],
    machine_item: {},
    machine_id: 0,
    valid: true,
    pincode: "",
    file: "",
    show1: false,
    show4: false,
    is_admin: false,
    first_name: '',
    last_name: '',
    user_name: '',
    password: '',
    password_confirm: '',
    email: '',
    rfidcode: '',

    email_rules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],


    department: '',
    nameRules: [
      v => !!v || 'Name is required',
      v => (v && v.length <= 10) || 'Name must be less than 10 characters',
    ],


    select: null,
    itemss: [],
    checkbox: false,

    baseUrl: baseUrl,
    items: [
      {
        text: 'หน้าหลัก',
        disabled: false,
        href: '/',
      },
      {
        text: 'การสะท้อนการเรียนรู้ วงรอบที่ 1',
        disabled: true,
        href: '/aar_1',
      },
    ],
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: 'รูปโปรไฟล์',
        align: 'start',
        sortable: false,
        value: 'image',
      },
      {text: 'ชื่อ', value: 'first_name'},
      {text: 'นามสกุล', value: 'last_name'},
      {text: 'แผนก', value: 'departments[0].title'},
      {text: 'RFID', value: 'rfidcode'},
      {text: 'Actions', value: 'actions', sortable: false},
    ],
    desserts: [],
    editedIndex: -1,
    resultData: [],

    activePicker: null,
    date: null,
    menu: false,

    name: "",
    school: "",
    amphur: "",
    province: "",
    class_detail: "",
    student_amount: "",
    course_select: 1,
    people_amount: 0,
    plan_detail: "",
    activity_plan: "",
    work_sheet: "",
    plan_problem_fix: "",

    cours_list: [
      {
        "label": "สังคมศึกษาศาสนาและวัฒนธรรม",
        "id": 1
      },
      {
        "label": "ภาษาไทย",
        "id": 2
      },
      {
        "label": "คอมพิวเตอร์ศึกษา",
        "id": 3
      }
    ],
    people_select: [],
    people_type_select: [],
    people_list: [
      {
        "label": "ผศ.ดร.อรัญ ซุยกระเดื่อง",
        "id": 1
      },
      {
        "label": "นายจักรพันธ์ แก้วกัณหา",
        "id": 2
      },
      {
        "label": "นางสาววราภรณ์ สุภาเฮือง",
        "id": 3
      }
    ],
    people_type: [
      {
        "label": "อาจารย์นิเทศ",
        "id": 1
      },
      {
        "label": "ครูพี่เลี้ยง",
        "id": 2
      },
      {
        "label": "นักศึกษา",
        "id": 3
      }
    ],

    time: null,
    menu2: false,
    modal2: false,
  }),

  computed: {},
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
  },

  created() {
    this.initialize()
    this.GetDepartment()
    this.GetMachines()
  },
  methods: {
    save(date) {
      this.$refs.menu.save(date)
    },
    initialize() {

    },
    async GetMachines() {
      let machine = [];
      const {status, results} = await GetMachine();
      if (status === 200) {

        results.data.forEach(function (result) {
          console.log(result)
          machine.push({
            "label": result.title,
            "id": result.machine_number,
          })
        });
      }

      this.machine_item = machine;
      console.log(this.itemss)

    },
    validate() {
      console.log(this.file)
      if (this.$refs.form.validate()) {
        this.UserRegister();
      }
    },
    reset() {
      this.$refs.form.reset()
      $('#image_preview').attr('src', '');
    },
    async UserRegister() {
      let Swal = this.$swal;

      let formData = new FormData();
      formData.append("first_name", this.first_name)
      formData.append("last_name", this.last_name)
      formData.append("user_name", this.user_name)
      formData.append("password", this.password)
      formData.append("email", this.email)
      formData.append("rfidcode", this.rfidcode)
      formData.append("pincode", this.pincode)
      formData.append("is_admin", this.is_admin ? 1 : 0)
      formData.append("department_id", this.department)
      formData.append("file", this.file)
      formData.append("machine_id", this.machine_id)


      const {status, results} = await UserRegister(formData, this.machine_id);
      if (status === 200) {
        Swal({
          icon: 'success',
          title: 'Success',
        })
        this.$refs.form.reset()
        $('#image_preview').attr('src', '');
        console.log(results)
      } else {

        Swal({
          icon: results.icon,
          title: results.message,
        })

      }

    },

    async GetDepartment() {
      let department = [];
      const {status, results} = await GetDepartment();
      if (status === 200) {

        results.data.forEach(function (result, index) {
          console.log(index)
          department.push({
            "label": result.title,
            "id": result.department_id,
          })
        });
      }

      this.itemss = department;
      console.log(this.itemss)

    },
    Preview_image(e) {
      if (e) {
        this.file = e;
        $('#image_preview').attr('src', URL.createObjectURL(e)); // jQuery selector
      }
    },
  },
  mounted() {

    print()
  }
}


</script>

<style scoped>
@media screen{
  .page_print{
    display: none;
  }
}
</style>
