<template>
  <div>
    <div class="page_print">
      <div align="center" >
        แบบรายงานการประเมินผลสะท้อนกลับ (AAR)
      </div><br>
      1) การสะท้อนการเรียนรู้ วงรอบที่ 1 การสะท้อนแผนการจัดการเรียนรู้ก่อนใช้สอน วงรอบที่ 1 (นักศึกษา อาจารย์ผู้สอน
      อาจารย์นิเทศ)<br><br>
      <div class="row_print">
        ชื่อนักศึกษา
        <div style="width: 318px;text-align: center" class="horizontal_dotted_line">นางสาววราภรณ์ สุภาเฮือง</div>
        โรงเรียน
        <div style="width: 270px;text-align: center" class="horizontal_dotted_line">นครขอนแก่น</div>
      </div>
      <div class="row_print">
        อำเภอ
        <div style="width: 300px;text-align: center" class="horizontal_dotted_line">เมืองขอนแก่น</div>
        จังหวัด
        <div style="width:314px;text-align: center" class="horizontal_dotted_line">ขอนแก่น</div>
      </div>
      <div class="row_print">
        วันที่สะท้อนการจัดกิจกรรมการเรียนรู้ก่อนเปิดชั้นเรียน
        <div style="width: 364px;text-align: center" class="horizontal_dotted_line">23 พฤษภาคม 2565 เวลา 09.00 น.</div>
      </div>
      <div class="row_print">
        นักเรียนชั้น
        <div style="width: 210px;text-align: center" class="horizontal_dotted_line">มัธยมศึกษาปีที 1/2</div>
        จำนวนนักเรียน
        <div style="width: 295px;text-align: center" class="horizontal_dotted_line">31</div>
        คน
      </div>
      <div class="row_print">
        รายวิชา/กลุ่มสาระการเรียนรู้
        <div style="width: 522px;text-align: center" class="horizontal_dotted_line">สังคมศึกษาศาสนาและวัฒนธรรม</div>
      </div>
      <div class="row_print">
        จำนวนผู้เข้าร่วมสะท้อนแผน จำนวน
        <div style="width: 100px;text-align: center" class="horizontal_dotted_line">3</div>
        คน ได้แก่
      </div>
      <div class="row_print">
        <table border="1" class="table_list">
          <tr>
            <th style="width:10% ">ลำดับที่</th>
            <th style="width:40%">ชื่อ-สกุล</th>
            <th style="width:30%">ผู้ร่วม AAR</th>
            <th style="width:20%">ลายมือชื่อ</th>
          </tr>
          <tr>
            <td>1.</td>
            <td>ผศ.ดร.อรัญ ซุยกระเดื่อง</td>
            <td>อาจารย์นิเทศ</td>
            <td></td>
          </tr>
          <tr>
            <td>2.</td>
            <td>นายจักรพันธ์ แก้วกัณหา</td>
            <td>ครูพี่เลี้ยง</td>
            <td></td>
          </tr>
          <tr>
            <td>3.</td>
            <td>นางสาววราภรณ์ สุภาเฮือง</td>
            <td>นักศึกษา</td>
            <td></td>
          </tr>
        </table>
      </div>
      <div class="row_print_des">
        1. องค์ประกอบของแผนจัดการเรียนรู้<br>
        <p class="description">
          องค์ประกอบของแผนการจัดการเรียนรู้ ประกอบด้วย สาระสำคัญ จุดประสงค์การเรียนรู้
          สาระการเรียนรู้ กิจกรรมการเรียนรู้ สื่อและแหล่งการเรียนรู้ การวัดและประเมินผล บันทึกหลังสอน
          ครบถ้วนสมบูรณ์

        </p>
      </div>

      <div class="row_print_des">
        2.การออกแบบจุดประสงค์การเรียนรู้ที่สอดคล้องกับกิจกรรมการเรียนรู้<br>
        <p class="description">
          จุดประสงค์การเรียนรู้ที่สอดคล้องกับกิจกรรมการเรียนรู้
        </p>
      </div>

      <div class="row_print_des">
        3. กิจกรรมการเรียนรู้ที่ครูผู้สอนออกแบบมีความสอดคล้องตามตัวชี้วัด<br>
        <p class="description">
          กิจกรรมการเรียนรู้ที่ออกแบบมีความสอดคล้องตามตัวชี้วัด
        </p>
      </div>

      <div class="row_print_des">
        4. ชิ้นงาน/ภาระงาน/การวัดประเมินผล สอดคล้องกับกิจกรรมการเรียนรู้<br>
        <p class="description">
          ชิ้นงาน ใบงาน แบบฝึกหัด และการวัดประเมินผล สอดคล้องกับกิจกรรมการเรียนรู้
        </p>
      </div>

      <div class="row_print_des">
        5. วิธีการแก้ไขปัญหาที่มีการวางแผนสู่การจัดกิจกรรมการเรียนรู้มีความเหมาะสม<br>
        <p class="description">
          ศึกษาเนื้อหาที่จะใช้ในการจัดการเรียนรู้ให้เข้าใจด้วยตนเองก่อน รวมถึงศึกษาพฤติกรรม
          ผู้เรียนในช่วงวัยที่เราจะทำการจัดการเรียนการสอน แล้วจึงมาวางแผนการจัดกิจกรรมการเรียนรู้
          ที่เหมาะสมกับผู้เรียน
        </p>
      </div>

    </div>
    <div class="page_print">
      <div class="row_print">
        สรุปเวลา
        <div style="width: 100px;text-align: center" class="horizontal_dotted_line">1</div>
        ชั่วโมง
      </div>
      <div class="license_list">
        <div class="license_item">
          <License
              name="นางสาววราภรณ์   สุภาเฮือง"
              type="ผู้บันทึก"
              position="นักศึกษา"
          />
        </div>
        <div class="license_item">
          <License
              name="นายจักรพันธุ์  แก้วกัณหา"
              type="ผู้รับรอง"
              position="ครูพี่เลี้ยง"
          />
        </div>
      </div>
      <div class="license_list">
        <div class="license_item">
          <License
              name="นางสาววราภรณ์   สุภาเฮือง"
              type="ผู้รับรอง"
              position="รองอำนวยการฝ่ายวิชาการโรงเรียน"
          />
        </div>
        <div class="license_item">
          <License
              name="ผศ.ดร.อรัญ  ซุยกระเดื่อง"
              type="ผู้รับรอง"
              position="อาจารย์นิเทศ"
          />
        </div>
      </div>
    </div>

    <div class="page_print">
      <div align="center" >
        แบบรายงานการประเมินผลสะท้อนกลับ (AAR)
      </div><br>
      2) การสะท้อนการเรียนรู้ วงรอบที่ 2 การสะท้อนการจัดการเรียนรู้วงรอบที่ 2 (ครูพี่เลี้ยงผู้บริหารสถานศึกษาและอาจารย์นิเทศ)<br><br>
      <div class="row_print">
        ชื่อนักศึกษา
        <div style="width: 318px;text-align: center" class="horizontal_dotted_line">นางสาววราภรณ์ สุภาเฮือง</div>
        โรงเรียน
        <div style="width: 270px;text-align: center" class="horizontal_dotted_line">นครขอนแก่น</div>
      </div>
      <div class="row_print">
        อำเภอ
        <div style="width: 300px;text-align: center" class="horizontal_dotted_line">เมืองขอนแก่น</div>
        จังหวัด
        <div style="width:314px;text-align: center" class="horizontal_dotted_line">ขอนแก่น</div>
      </div>
      <div class="row_print">
        วันที่สะท้อนการจัดกิจกรรมการเรียนรู้ก่อนเปิดชั้นเรียน
        <div style="width: 364px;text-align: center" class="horizontal_dotted_line">23 พฤษภาคม 2565 เวลา 09.00 น.</div>
      </div>
      <div class="row_print">
        นักเรียนชั้น
        <div style="width: 210px;text-align: center" class="horizontal_dotted_line">มัธยมศึกษาปีที 1/2</div>
        จำนวนนักเรียน
        <div style="width: 295px;text-align: center" class="horizontal_dotted_line">31</div>
        คน
      </div>
      <div class="row_print">
        รายวิชา/กลุ่มสาระการเรียนรู้
        <div style="width: 522px;text-align: center" class="horizontal_dotted_line">สังคมศึกษาศาสนาและวัฒนธรรม</div>
      </div>
      <div class="row_print">
        จำนวนผู้เข้าร่วมสะท้อนแผน จำนวน
        <div style="width: 100px;text-align: center" class="horizontal_dotted_line">3</div>
        คน ได้แก่
      </div>
      <div class="row_print">
        <table border="1" class="table_list">
          <tr>
            <th style="width:10% ">ลำดับที่</th>
            <th style="width:40%">ชื่อ-สกุล</th>
            <th style="width:30%">ผู้ร่วม AAR</th>
            <th style="width:20%">ลายมือชื่อ</th>
          </tr>
          <tr>
            <td>1.</td>
            <td>ผศ.ดร.อรัญ ซุยกระเดื่อง</td>
            <td>อาจารย์นิเทศ</td>
            <td></td>
          </tr>
          <tr>
            <td>2.</td>
            <td>นายจักรพันธ์ แก้วกัณหา</td>
            <td>ครูพี่เลี้ยง</td>
            <td></td>
          </tr>
          <tr>
            <td>3.</td>
            <td>นางสาววราภรณ์ สุภาเฮือง</td>
            <td>นักศึกษา</td>
            <td></td>
          </tr>
        </table>
      </div>
      <div class="row_print_des">
        1. สิ่งที่ครูผู้สอนทำได้ดีและควรรักษาไว้ให้มีต่อไป <br>
        <p class="description">
          มีความรับผิดชอบ เอาใจใส่ดูแลนักเรียน และใส่ใจหน้าที่ตัวเองได้รับมอบหมาย มีจิตอาสาในการช่วยงานอื่นๆของโรงเรียนนอกเหนือจากงานสอน เช่นกิจกรรมนักเรียน โครงการ งานวันสำคัญต่างๆของทางโรงเรียน
        </p>
      </div>

      <div class="row_print_des">
        2.สิ่งที่เป็นปัญหาและอุปสรรคที่ทำให้การจัดกิจกรรมการเรียนรู้ในครั้งนี้ไม่เป็นไปตามเป้าหมาย<br>
        <p class="description">
          ในห้องเรียนบ้างครั้งที่มีการใช้สื่อนวัตกรรมทางการศึกษา เช่นมี VDO หรือสไลน์ห้องเรียนบางห้องมีอุปกรณ์การเรียนการสอนไม่ครบ ต้องปรับกิจกรรมเป็นใบงานหรือการบรรยายเพิ่มเติม
        </p>
      </div>

      <div class="row_print_des">
        3. สิ่งที่ครูต้องปรับให้ดีขึ้นเพื่อพัฒนาการเรียนรู้ของนักเรียนมีประเด็นโดบ้างและจะทำอย่างไร<br>
        <p class="description">
          มีการใช้สื่อหรือนวัตกรรมเข้ามาเกี่ยวข้องในส่วนของการรรวมกิจกรรมการเรียนรู้เพื่อให้ผู้เรียนสามารถมองเห็นเป็นรูปธรรมมากยิ่งขึ้น
        </p>
      </div>

      <div class="row_print_des">
        4. นักเรียนบรรลุวัตถุประสงค์การเรียนรู้ในครั้งนี้จำนวนกี่คน<br>
        <p class="description">
          นักเรียนที่บรรลุวัตถุประสงค์การเรียนรู้ในครั้งนี้มีจำนวน 29 คน ส่วนนักเรียนที่เหลืออาจจะยังไม่
          เข้าใจในเนื้อหาบางส่วน แต่ทางผู้สอนก็ได้ทบทวนในส่วนของเนื้อหาที่นักเรียนบางคนไม่เข้าใจ และยกตัวอย่าง
          สถานการณ์ให้นักเรียนเข้าใจมากยิ่งขึ้น
        </p>
      </div>

      <div class="row_print_des">
        5. วิธีการแก้ปัญหาที่นำมาใช้ในการจัดกิจกรรมการเรียนรู้เกิดผลอย่างไร<br>
        <p class="description">
          ทำให้ผู้เรียนนั้นสามารถเข้าใจและเรียนรู้ในเนื้อหาการเรียน และการใช้สื่อนวัตกรรมที่ผู้สอน
          ได้เตรียมมา ซึ่งผู้เรียนได้ลงมือปฏิบัติจริง อีกทั้งยังเป็นการเปิดโอกาสให้ผู้เรียนนั้น กล้าที่จะปรึกษาครูผู้สอน
          ในเรื่องที่ตนเองไม่เข้าใจ มีการละลายพฤติกรรมก่อนการทำกิจกรรมการเรียนรู้ในแต่ละชั่วโมงเพื่อให้นักเรียน
          ผ่อนคลายและไม่เครียดในการเรียนมากเกินไป
        </p>
      </div>

    </div>
    <div class="page_print">
      <div class="row_print">
        สรุปเวลา
        <div style="width: 100px;text-align: center" class="horizontal_dotted_line">1</div>
        ชั่วโมง
      </div>
      <div class="license_list">
        <div class="license_item">
          <License
              name="นางสาววราภรณ์   สุภาเฮือง"
              type="ผู้บันทึก"
              position="นักศึกษา"
          />
        </div>
        <div class="license_item">
          <License
              name="นายจักรพันธุ์  แก้วกัณหา"
              type="ผู้รับรอง"
              position="ครูพี่เลี้ยง"
          />
        </div>
      </div>
      <div class="license_list">
        <div class="license_item">
          <License
              name="นางสาววราภรณ์   สุภาเฮือง"
              type="ผู้รับรอง"
              position="รองอำนวยการฝ่ายวิชาการโรงเรียน"
          />
        </div>
        <div class="license_item">
          <License
              name="ผศ.ดร.อรัญ  ซุยกระเดื่อง"
              type="ผู้รับรอง"
              position="อาจารย์นิเทศ"
          />
        </div>
      </div>
    </div>
    <div class="page_print" >
      3) การทบทวนหลังการปฏิบัติงาน (After Action Review : AAR)<br><br>
      <div align="center" >
        แบบบันทึกการทบทวนหลังการปฏิบัติงาน (After Action Review : AAR)<br>
        การทบทวนหลังการปฏิบัติการสอนในสถานศึกษา<br>
        หน่วยงาน <b>โรงเรียนนครขอนแก่น</b>
      </div><br>
      กระบวนการจัดการความรู้มี 7 กิจกรรม ดังนี้ <br><br>

      <p class="description_question">
        1) การบ่งชี้ความรู้ คือ <b>การระบุประเด็นความรู้ รูปแบบ และผู้รู้ที่สอดรับกับนโยบาย ขอบเขต และเป้าหมายขององค์กร</b>
      </p>
      <p class="description_question">
        2) การสร้างและแสวงหา ความรู้ คือ <b>การรวบรวมความรู้ใหม่ รักษาความรู้เดิม และกรองความรู้ที่ไม่ใช้ออกจากแหล่งรวบรวม</b>
      </p>
      <p class="description_question">
        3) การจัดความรู้ให้เป็นระบบ คือ <b>การวิเคราะห์และคัดแยกความรู้เป็นกลุ่มประเด็นให้ง่ายต่อการเข้าถึงอย่างมีขั้นตอน</b>
      </p>
      <p class="description_question">
        4) การประมวลและกลั่นกรอง ความรู้ คือ  <b>การปรับปรุงให้ความรู้มีรูปแบบมารฐาน ไม่ซับซ้อน มีความสมบูรณ์ มีความถูกต้องและน่าเชื่อถือ</b>
      </p>
      <p class="description_question">
        5) การเข้าถึงความรู้ คือ   <b>การสร้างแหล่งเผยแพร่ที่สามารถเข้าถึงได้ทุกที่ทุกเวลา</b>
      </p>
      <p class="description_question">
        6) การแบ่งปันแลกเปลี่ยนความรู้ คือ   <b>การนำความรู้เข้าสู่เวทีแลกเปลี่ยนที่มีฐานความรู้หรือฐานข้อมูลรองรับให้ง่ายต่อการเข้าถึงและสืบค้น</b>
      </p>
      <p class="description_question">
        7) การเรียนรู้ คือ    <b>การใช้ความรู้เป็นส่วนหนึ่งของงาน เป็นวงจรความรู้ที่มีการเรียนรู้และพัฒนาให้เกิดประสบการณ์ใหม่อยู่เสมอ </b>
      </p>

    </div>
    <div class="page_print" >
      <table border="1" class="table_list_des" >
        <tr>
          <th style="width: 20%;text-align: left;padding-left: 10px;" >หน่วยงาน</th>
          <th style="width: 80%;text-align: left;padding-left: 10px;" >โรงเรียนนครขอนแก่น</th>
        </tr>
        <tr>
          <th style="width: 20%;text-align: left;padding-left: 10px;" >ชื่องาน</th>
          <th style="width: 80%;text-align: left;padding-left: 10px;" >การปฏิบัติการสอนในสถานศึกษา 1</th>
        </tr>
        <tr>
          <th colspan="2" style="text-align: left;padding-left: 10px;" >เป้าหมายของงาน</th>
        </tr>
        <tr>
          <th colspan="2" style="text-align: left;padding-left: 10px;" >
            สามารถเก็บเกี่ยวประสบการณ์การทำงานในแต่ละหน้าที่ต่าง ๆ ที่ได้รับมอบหมาย การร่วมกิจกรรมของทางโรงเรียนในทุก ๆ กิจกรรมที่ได้จัดทำขึ้น มีการแบ่งหน้าที่ตามความรับผิดชอบและความถนัดของตนเองซึ่งจะทำให้งานที่ออกมานั้นประสบผลสำเร็จมายิ่งขึ้น
          </th>
        </tr>
        <tr>
          <th colspan="2" style="text-align: left;padding-left: 10px;" >ผลการปฏิบัติ/ผลลัพธ์ที่เกิดขึ้นจริง (โดยสรุป)</th>
        </tr>
        <tr>
          <th colspan="2" style="text-align: left;padding-left: 10px;" >
            ผลจากการปฏิบัติงานในแต่ละหน้าที่ได้รับผิดชอบนั้น ในแต่ละกิจกรรมที่ได้เป็นทั้งผู้นำและผู้ตามซึ่งปฏิบัติหน้าที่ได้ออกมาตีที่สุดและเต็มความสามารถมากที่สุด มีการแก้ไขปัญหาเฉพาะหน้าได้ดียิ่งขึ้นรวมไปถึงได้มีการ ทราบถึงปัญหาและอุปสรรคในการปฏิบัติงานกับคนหมู่มาก อีกทั้งได้นำเอามาปรับใช้ในการจัดกิจกรรมโครงการ รวมไปถึงการจัดกิจกรรมการเรียนรู้
          </th>
        </tr>
        <tr>
          <th colspan="2" style="text-align: left;padding-left: 10px;" >งาน/ขั้นตอนที่ทำได้ดี </th>
        </tr>
        <tr>
          <th colspan="2" style="text-align: left;padding-left: 10px;" >
            งานที่ทำได้ดีก็จะเป็นการเตรียมการสอน การจะทำเอกสาร การสร้างสื่อที่ใช้ในการจัดกิจกรรมการเรียนรู้
            ต่าง ๆ รวมไปถึงการที่เป็นผู้นำและผู้ตามที่ดีในแต่ละกิจกรรมที่ทางโรงเรียนและทางนักศึกษาได้จัดทำขึ้น
            อีกทั้งเป็นคนมีความกระตือรือร้น มีความรับผิดชอบในหน้าที่ หรือขึ้นงานที่ตนเองได้รับมอบหมายและมีการเอาใจใส่เด็กนักเรียนและหน้าที่ของตนเอง
          </th>
        </tr>
        <tr>
          <th colspan="2" style="text-align: left;padding-left: 10px;" >งาน/ขั้นตอนที่ทำได้ไม่ดี </th>
        </tr>
        <tr>
          <th colspan="2" style="text-align: left;padding-left: 10px;" >
            การร่วมกิจกรรมบางกิจกรรมที่ไม่เกี่ยวข้องกับเราโดยตรง อาจจะเข้าร่วมบ้าง บางครั้ง
          </th>
        </tr>
        <tr>
          <th colspan="2" style="text-align: left;padding-left: 10px;" >อุปสรรค/ข้อจำกัด/ข้อขัดข้อง ที่พบในระหว่างการปฏิบัติงาน </th>
        </tr>
        <tr>
          <th colspan="2" style="text-align: left;padding-left: 10px;" >
            สิ่งที่พบเจอในระหว่างปฏิบัติงานก็คือ ได้เห็นวิธีการทำงานของแต่ละคน ได้เข้าใจว่าแต่ละคนมีวิธีคิดกับงานที่ได้รับมอบหมายยังไงบ้าง รวมไปถึงการปฏิบัติตนกับเพื่อนร่วมงานของบุคคลอื่น ๆ และตัวเราเองว่ามีการปฏิบัติตนอย่างไร
          </th>
        </tr>
        <tr>
          <th colspan="2" style="text-align: left;padding-left: 10px;" >ประเด็นที่ได้เรียนรู้</th>
        </tr>
        <tr>
          <th colspan="2" style="text-align: left;padding-left: 10px;" >
            ได้เรียนรู้อะไรหลาย ๆ อย่าง ไม่ว่าจะเป็น เรื่องการสอน การกิจกรรมการเรียนรู้อย่างไรให้ผู้เรียนมีความสนใจและกระตือรือร้นในการเรียนอยู่เสมอ การเปิดใจกับนักเรียนให้นักเรียนรู้สึกปลอดภัยเมื่อได้เรียนในรายวิชาของเรา ได้มีแนวคิดในการพัฒนาสื่อและนวัตกรรมเพื่อนำมาใช้ในการจัดกิจกรรมการเรียนรู้ และได้มีการทำกิจกรรมหรือ คิดแนวทางในการทำงานร่วมแลกเปลี่ยนเรียนรู้กับผู้อื่น
          </th>
        </tr>
        <tr>
          <th colspan="2" style="text-align: left;padding-left: 10px;" >ข้อปฏิบัติในการทำงานครั้งต่อไป </th>
        </tr>
        <tr>
          <th colspan="2" style="text-align: left;padding-left: 10px;" >
            ทำหน้าที่ที่ตนเองได้รับมอบหมายให้ดีที่สุด มีความกระตือรือร้น มีความรอบคอบ รู้จักวางแผนและแก้ไขปัญหาเฉพาะหน้าให้ดีขึ้น
          </th>
        </tr>
      </table>
    </div>

  </div>
</template>

<script>
import {GetDepartment, GetMachine, UserRegister} from "../../lib/controller";
import License from "@/components/License";
import {baseUrl} from "../../const/api";
import $ from 'jquery'

export default {
  name: "Users",
  components: {
    License
  },
  data: () => ({
    rules: [
      value => !value || value.size < 2000000 || 'Avatar size should be less than 2 MB!',
    ],
    machine_item: {},
    machine_id: 0,
    valid: true,
    pincode: "",
    file: "",
    show1: false,
    show4: false,
    is_admin: false,
    first_name: '',
    last_name: '',
    user_name: '',
    password: '',
    password_confirm: '',
    email: '',
    rfidcode: '',

    email_rules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],


    department: '',
    nameRules: [
      v => !!v || 'Name is required',
      v => (v && v.length <= 10) || 'Name must be less than 10 characters',
    ],


    select: null,
    itemss: [],
    checkbox: false,

    baseUrl: baseUrl,
    items: [
      {
        text: 'หน้าหลัก',
        disabled: false,
        href: '/',
      },
      {
        text: 'การสะท้อนการเรียนรู้ วงรอบที่ 1',
        disabled: true,
        href: '/aar_1',
      },
    ],
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: 'รูปโปรไฟล์',
        align: 'start',
        sortable: false,
        value: 'image',
      },
      {text: 'ชื่อ', value: 'first_name'},
      {text: 'นามสกุล', value: 'last_name'},
      {text: 'แผนก', value: 'departments[0].title'},
      {text: 'RFID', value: 'rfidcode'},
      {text: 'Actions', value: 'actions', sortable: false},
    ],
    desserts: [],
    editedIndex: -1,
    resultData: [],

    activePicker: null,
    date: null,
    menu: false,

    name: "",
    school: "",
    amphur: "",
    province: "",
    class_detail: "",
    student_amount: "",
    course_select: 1,
    people_amount: 0,
    plan_detail: "",
    activity_plan: "",
    work_sheet: "",
    plan_problem_fix: "",

    cours_list: [
      {
        "label": "สังคมศึกษาศาสนาและวัฒนธรรม",
        "id": 1
      },
      {
        "label": "ภาษาไทย",
        "id": 2
      },
      {
        "label": "คอมพิวเตอร์ศึกษา",
        "id": 3
      }
    ],
    people_select: [],
    people_type_select: [],
    people_list: [
      {
        "label": "ผศ.ดร.อรัญ ซุยกระเดื่อง",
        "id": 1
      },
      {
        "label": "นายจักรพันธ์ แก้วกัณหา",
        "id": 2
      },
      {
        "label": "นางสาววราภรณ์ สุภาเฮือง",
        "id": 3
      }
    ],
    people_type: [
      {
        "label": "อาจารย์นิเทศ",
        "id": 1
      },
      {
        "label": "ครูพี่เลี้ยง",
        "id": 2
      },
      {
        "label": "นักศึกษา",
        "id": 3
      }
    ],

    time: null,
    menu2: false,
    modal2: false,
  }),

  computed: {},
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
  },

  created() {
    this.initialize()
    this.GetDepartment()
    this.GetMachines()
  },
  methods: {
    save(date) {
      this.$refs.menu.save(date)
    },
    initialize() {

    },
    async GetMachines() {
      let machine = [];
      const {status, results} = await GetMachine();
      if (status === 200) {

        results.data.forEach(function (result) {
          console.log(result)
          machine.push({
            "label": result.title,
            "id": result.machine_number,
          })
        });
      }

      this.machine_item = machine;
      console.log(this.itemss)

    },
    validate() {
      console.log(this.file)
      if (this.$refs.form.validate()) {
        this.UserRegister();
      }
    },
    reset() {
      this.$refs.form.reset()
      $('#image_preview').attr('src', '');
    },
    async UserRegister() {
      let Swal = this.$swal;

      let formData = new FormData();
      formData.append("first_name", this.first_name)
      formData.append("last_name", this.last_name)
      formData.append("user_name", this.user_name)
      formData.append("password", this.password)
      formData.append("email", this.email)
      formData.append("rfidcode", this.rfidcode)
      formData.append("pincode", this.pincode)
      formData.append("is_admin", this.is_admin ? 1 : 0)
      formData.append("department_id", this.department)
      formData.append("file", this.file)
      formData.append("machine_id", this.machine_id)


      const {status, results} = await UserRegister(formData, this.machine_id);
      if (status === 200) {
        Swal({
          icon: 'success',
          title: 'Success',
        })
        this.$refs.form.reset()
        $('#image_preview').attr('src', '');
        console.log(results)
      } else {

        Swal({
          icon: results.icon,
          title: results.message,
        })

      }

    },

    async GetDepartment() {
      let department = [];
      const {status, results} = await GetDepartment();
      if (status === 200) {

        results.data.forEach(function (result, index) {
          console.log(index)
          department.push({
            "label": result.title,
            "id": result.department_id,
          })
        });
      }

      this.itemss = department;
      console.log(this.itemss)

    },
    Preview_image(e) {
      if (e) {
        this.file = e;
        $('#image_preview').attr('src', URL.createObjectURL(e)); // jQuery selector
      }
    },
  },
  mounted() {
    print()
  }
}


</script>

<style scoped>
@media screen{
  .page_print{
    display: none;
  }
}

</style>
