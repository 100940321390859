<template>
  <v-breadcrumbs :items="items">
    <template v-slot:item="{ item }">
      <v-breadcrumbs-item
          :to="item.href"
          :disabled="item.disabled"
      >
        {{ item.text.toUpperCase() }}
      </v-breadcrumbs-item>
    </template>
    <div class="card_container" >
      <div class="card_header" >
        <div>รายละเอียดแผนพัฒนาตนเองรายบุคคล </div>
      </div>

      <v-breadcrumbs :items="items">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item
              :to="item.href"
              :disabled="item.disabled"
          >
            {{ item.text.toUpperCase() }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
      <div class="form_card" >


        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
        >

          <v-text-field
              v-model="competence_develop"
              :rules="[v => !!v || ' กรุณากรอกสมรรถนะที่จะพัฒนา']"
              label="รายการสมรรถนะที่จะพัฒนา"
              required
          ></v-text-field>

          <v-text-field
              v-model="topic"
              :rules="[v => !!v || 'กรุณากรอกหัวข้อ/ประเด็นการพัฒนา']"
              label="หัวข้อ/ประเด็นการพัฒนา"
              required
          ></v-text-field>

          <v-row>
            <v-col
                cols="12"
                lg="6"
            >
              <v-menu
                  v-model="menu1"
                  :close-on-content-click="false"
                  max-width="290"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      :value="date_start"
                      clearable
                      label="วันที่เริ่มต้น"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      @click:clear="date_start = null"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="date_start"
                    @change="menu1 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col
                cols="12"
                lg="6"
            >
              <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  max-width="290"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      :value="date_end"
                      clearable
                      label="วันที่สิ้นสุด"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      @click:clear="date_end = null"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="date_end"
                    @change="menu2 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>



          <v-text-field
              v-model="development_learning_resources"
              :rules="[v => !!v || 'กรุณากรอกแหล่งพัฒนา/แหล่งเรียนรู้']"
              label="แหล่งพัฒนา/แหล่งเรียนรู้"
              required
          ></v-text-field>

          <v-text-field
              v-model="expected_results"
              :rules="[v => !!v || 'กรุณากรอกหน่วยงานที่ดำเนินการพัฒนา']"
              label="ผลที่คาดว่าจะได้รับ"
              required
          ></v-text-field>

          <v-text-field
              v-model="note"
              :rules="[v => !!v || 'กรุณากรอกหมายเหตุ']"
              label="หมายเหตุ"
              required
          ></v-text-field>


          <div align="center" style="margin-top: 2rem" >
            <v-btn
                color="success"
                class="mr-4"
                @click="validate"
            >
              อัพเดท
            </v-btn>

            <v-btn
                color="error"
                class="mr-4"
                @click="reset"
            >
             ล้างค่า
            </v-btn>

            <v-btn
                color="success"
                class="mr-4"
                @click="print_page"
            >
              ปริ้น
            </v-btn>


          </div>

        </v-form>
      </div>

    </div>
  </v-breadcrumbs>



</template>

<script>
import {GetDepartment, GetMachine, UserRegister} from "../../lib/controller";
import {baseUrl} from "../../const/api";
import $ from 'jquery'

export default {
  name: "Users",
  data: () => ({
    rules: [
      value => !value || value.size < 2000000 || 'Avatar size should be less than 2 MB!',
    ],
    machine_item:{},
    machine_id:0,
    valid: true,
    pincode:"",
    file:"",
    show1: false,
    show4: false,
    is_admin:false,
    user_name: '',
    password: '',
    password_confirm: '',
    email: '',
    rfidcode: '',

    email_rules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],


    department: '',
    nameRules: [
      v => !!v || 'Name is required',
      v => (v && v.length <= 10) || 'Name must be less than 10 characters',
    ],



    select: null,
    itemss:[],
    checkbox: false,

    baseUrl:baseUrl,
    items: [
      {
        text: 'หน้าหลัก',
        disabled: false,
        href: '/',
      },
      {
        text: 'รายละเอียดแผนพัฒนาตนเองรายบุคคล ',
        disabled: true,
        href: '/plan_detail',
      },
    ],
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: 'รูปโปรไฟล์',
        align: 'start',
        sortable: false,
        value: 'image',
      },
      { text: 'ชื่อ', value: 'first_name' },
      { text: 'นามสกุล', value: 'last_name' },
      { text: 'แผนก', value: 'departments[0].title' },
      { text: 'RFID', value: 'rfidcode' },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
    desserts: [],
    editedIndex: -1,
    resultData:[],

    activePicker: null,
    date: null,
    menu: false,

    first_name:"",
    last_name:"",
    sex:"",
    course_select:1,
    cours_list:[
      {
        "label":"สังคมศึกษาศาสนาและวัฒนธรรม",
        "id":1
      },
      {
        "label":"ภาษาไทย",
        "id":2
      },
      {
        "label":"คอมพิวเตอร์ศึกษา",
        "id":3
      }
    ],
    people_select:[],
    competence_develop:"",
    topic:"",
    affiliation:"",
    date_start:"",
    date_end:"",
    development_learning_resources:"",
    expected_results:"",
    note:"",



    people_type_select:[],
    people_list:[
      {
        "label":"ผศ.ดร.อรัญ ซุยกระเดื่อง",
        "id":1
      },
      {
        "label":"นายจักรพันธ์ แก้วกัณหา",
        "id":2
      },
      {
        "label":"นางสาววราภรณ์ สุภาเฮือง",
        "id":3
      }
    ],
    people_type:[
      {
        "label":"อาจารย์นิเทศ",
        "id":1
      },
      {
        "label":"ครูพี่เลี้ยง",
        "id":2
      },
      {
        "label":"นักศึกษา",
        "id":3
      }
    ],

    time: null,
    menu2: false,
    modal2: false,
  }),

  computed: {

  },
  watch: {
    menu (val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
  },

  created () {
    this.initialize()
    this.GetDepartment()
    this.GetMachines()
  },
  methods: {
    save (date) {
      this.$refs.menu.save(date)
    },
    print_page(){
      window.open('./plan_print', '_blank');
    },
    initialize () {

    },
    async GetMachines(){
      let machine = [];
      const { status , results } = await GetMachine();
      if(status === 200){

        results.data.forEach(function (result){
          console.log(result)
          machine.push({
            "label":result.title,
            "id":result.machine_number,
          })
        });
      }

      this.machine_item = machine;
      console.log(this.itemss)

    },
    validate () {
      console.log(this.file)
      if(this.$refs.form.validate()){
        this.UserRegister();
      }
    },
    reset () {
      this.$refs.form.reset()
      $('#image_preview').attr('src', '');
    },
    async UserRegister(){
      let Swal = this.$swal;

      let formData = new FormData();
      formData.append("first_name",this.first_name)
      formData.append("last_name",this.last_name)
      formData.append("user_name",this.user_name)
      formData.append("password",this.password)
      formData.append("email",this.email)
      formData.append("rfidcode",this.rfidcode)
      formData.append("pincode",this.pincode)
      formData.append("is_admin",this.is_admin ? 1 : 0)
      formData.append("department_id",this.department)
      formData.append("file",this.file)
      formData.append("machine_id",this.machine_id)


      const { status , results } = await UserRegister(formData,this.machine_id);
      if(status === 200){
        Swal({
          icon: 'success',
          title: 'Success',
        })
        this.$refs.form.reset()
        $('#image_preview').attr('src', '');
        console.log(results)
      }else{

        Swal({
          icon: results.icon,
          title: results.message,
        })

      }

    },

    async GetDepartment(){
      let department = [];
      const { status , results } = await GetDepartment();
      if(status === 200){

        results.data.forEach(function (result,index){
          console.log(index)
          department.push({
            "label":result.title,
            "id":result.department_id,
          })
        });
      }

      this.itemss = department;
      console.log(this.itemss)

    },
    Preview_image(e) {
      if (e) {
        this.file = e;
        $('#image_preview').attr('src', URL.createObjectURL(e)); // jQuery selector
      }
    },
  },
}


</script>

<style scoped>
.card_container{
  width: 100%;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 1px solid #EBEDF3;
  border-radius: 0.42rem;
}
.card_header{
  padding: 1.5rem 2rem;
  margin-bottom: 0;
  background-color: #ffffff;
  border-bottom: 1px solid #EBEDF3;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.form_card{
  padding:2rem;
}
.image_preview_container{
  display: flex;
  justify-content: center;
  align-items: center;
}
#image_preview{
  width: 20rem;
  text-align: center;
}
.custom_table{
  border-collapse: collapse;
}
.table_container{
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.list_check_box{
  display: flex;
  gap: 20px;
}
</style>
