<template>
  <v-breadcrumbs :items="items">
    <template v-slot:item="{ item }">
      <v-breadcrumbs-item
          :to="item.href"
          :disabled="item.disabled"
      >
        {{ item.text.toUpperCase() }}
      </v-breadcrumbs-item>
    </template>
    <div class="card_container" >
      <div class="card_header" >
        <div>ประวัติการเข้ารับการพัฒนาตนเอง </div>
      </div>

      <v-breadcrumbs :items="items">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item
              :to="item.href"
              :disabled="item.disabled"
          >
            {{ item.text.toUpperCase() }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
      <div class="form_card" >


        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
        >

          <h2>ข้อมูลความต้องการในการพัฒนา </h2>

          <h3>ประเภทหลักสูตรที่ต้องการพัฒนา (สามารถเลือกได้มากกว่า 1 หลักสูตร หรือระบุหลักสูตรอื่นๆ) </h3><br>

          <v-checkbox
              v-model="checkbox_1"
              label="การสอนในศตวรรษที่21"
          ></v-checkbox>

          <v-checkbox
              v-model="checkbox_2"
              label="การแก้ปัญหาผู้เรียน "
          ></v-checkbox>

          <v-checkbox
              v-model="checkbox_3"
              label="จิตวิทยาการแนะแนว/จิตวิทยาการจัดการเรียนรู้  "
          ></v-checkbox>

          <v-checkbox
              v-model="checkbox_4"
              label="การจัดการชั้นเรียน "
          ></v-checkbox>

          <v-checkbox
              v-model="checkbox_5"
              label="การวิจัยพัฒนาการเรียนการสอน/ชุมชนแห่งการเรียนรู้ทางวิชาชีพ "
          ></v-checkbox>

          <v-checkbox
              v-model="checkbox_6"
              label="การพัฒนาหลักสูตร "
          ></v-checkbox>

          <v-checkbox
              v-model="checkbox_7"
              label="สะเต็มศึกษา (STEM Education)  "
          ></v-checkbox>

          <v-checkbox
              v-model="checkbox_8"
              label="การใช้สื่อและเทคโนโลยีในการจัดการเรียนรู้ "
          ></v-checkbox>

          <v-checkbox
              v-model="checkbox_9"
              label="การวัดและประเมินผลการเรียนรู้"
          ></v-checkbox>


          <v-checkbox
              v-model="checkbox_10"
              label="การออกแบบการเรียนรู้"
          ></v-checkbox>

          <v-checkbox
              v-model="checkbox_11"
              label="ทักษะภาษาอังกฤษ"
          ></v-checkbox>

          <v-checkbox
              v-model="checkbox_12"
              label="ทักษะด้านเทคโนโลยีดิจิทัล"
          ></v-checkbox>

          <v-checkbox
              v-model="checkbox_13"
              label="การจัดการเรียนการสอนเพื่อฝึกทักษะการคิดแบบมีเหตุผลและเป็นขั้นตอน  หรือ Coding"
          ></v-checkbox>


          <div class="list_check_box" >
            <v-checkbox
                v-model="checkbox_other"
                label="อื่นๆ"
            ></v-checkbox>

            <v-text-field
                v-if="checkbox_other"
                v-model="list_develop"
                :rules="[v => !!v || 'กรอกนากรอกอื่นๆ']"
                label="อื่นๆ"
                required
            ></v-text-field>

          </div>

          <h3>รูปแบบวิธีการที่พัฒนา (สามารถเลือกได้มากกว่า 1 วิธีการ หรืออื่นๆโปรดระบุ) </h3><br>


          <v-checkbox
              v-model="checkbox_2_1"
              label="รับการฝกจากการปฏิบัติงาน (On the Job Training)"
          ></v-checkbox>

          <v-checkbox
              v-model="checkbox_2_2"
              label="รับการสอนงาน (Coaching) จากผู้มีประสบการณ์"
          ></v-checkbox>

          <v-checkbox
              v-model="checkbox_2_3"
              label="รับการฝึกปฏิบัติโดยระบบพี่เลี้ยง (Mentoring)"
          ></v-checkbox>

          <v-checkbox
              v-model="checkbox_2_4"
              label="รับการให้คำปรึกษาแนะนำ (Consulting) จากผู้เชี่ยวชาญ"
          ></v-checkbox>

          <v-checkbox
              v-model="checkbox_2_5"
              label="การหมุนเวียนงาน (Job Rotation) ภายในหน่วยงาน / การใหปฏิบัติงานในหน่วยงานอื่น "
          ></v-checkbox>

          <v-checkbox
              v-model="checkbox_2_6"
              label="การเข้าร่วมประชุม / สัมมนา (Meeting / Seminar) จากหน่วยงานต้นสังกัด"
          ></v-checkbox>

          <v-checkbox
              v-model="checkbox_2_7"
              label="การเข้าร่วมประชุม / สัมมนา (Meeting / Seminar) จากหน่วยงานภายนอก"
          ></v-checkbox>

          <v-checkbox
              v-model="checkbox_2_8"
              label="การเข้าร่วมประชุม / สัมมนา (Meeting / Seminar) จากหน่วยงานภายนอก"
          ></v-checkbox>

          <v-checkbox
              v-model="checkbox_2_9"
              label="การดูงานนอกสถานที่ (Site Visit)"
          ></v-checkbox>

          <v-checkbox
              v-model="checkbox_2_10"
              label="การฝึกงานกับผู้เชี่ยวชาญ (Counterpart) "
          ></v-checkbox>

          <v-checkbox
              v-model="checkbox_2_11"
              label="การมอบหมายงาน (Job Assignment) "
          ></v-checkbox>

          <v-checkbox
              v-model="checkbox_2_12"
              label="การติดตามหัวหนา (Work Shadow) "
          ></v-checkbox>

          <v-checkbox
              v-model="checkbox_2_13"
              label="การเรียนรูด้วยตนเอง (Self-Learning) "
          ></v-checkbox>


          <v-checkbox
              v-model="checkbox_2_14"
              label="การเป็นวิทยากรภายในหน่วยงาน (In-House Instructor)"
          ></v-checkbox>

          <div class="list_check_box" >
            <v-checkbox
                v-model="checkbox_other_2"
                label="อื่นๆ"
            ></v-checkbox>
ุ
            <v-text-field
                v-if="checkbox_other_2"
                v-model="list_develop"
                :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
                label="อื่นๆ"
                required
            ></v-text-field>

          </div>

          <v-textarea
              outlined
              label="รายละเอียดจำเพาะหลักสูตรที่ต้องการพัฒนา "
              :value="specific_details_develop"
              :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
          ></v-textarea>


          <v-textarea
              outlined
              label="เพราะเหตุใดท่านจึงต้องการเข้ารับการพัฒนาในหลักสูตรนี้ "
              :value="why_want_develop_course"
              :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
          ></v-textarea>

          <v-textarea
              outlined
              label="ท่านคาดหวังสิ่งใดจากการเข้ารับการพัฒนาในหลักสูตรนี้ "
              :value="what_do_you_expect"
              :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
          ></v-textarea>

          <v-textarea
              outlined
              label="ท่านจะนำความรู้จากหลักสูตรไปพัฒนาการสอนของท่านอย่างไร "
              :value="what_do_you_expect"
              :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
          ></v-textarea>



          <div align="center" style="margin-top: 2rem" >
            <v-btn
                color="success"
                class="mr-4"
                @click="validate"
            >
              อัพเดท
            </v-btn>

            <v-btn
                color="error"
                class="mr-4"
                @click="reset"
            >
             ล้างค่า
            </v-btn>

            <v-btn
                color="success"
                class="mr-4"
                @click="print_page"
            >
              ปริ้น
            </v-btn>


          </div>

        </v-form>
      </div>

    </div>
  </v-breadcrumbs>



</template>

<script>
import {GetDepartment, GetMachine, UserRegister} from "../../lib/controller";
import {baseUrl} from "../../const/api";
import $ from 'jquery'

export default {
  name: "Users",
  data: () => ({
    rules: [
      value => !value || value.size < 2000000 || 'Avatar size should be less than 2 MB!',
    ],
    machine_item:{},
    machine_id:0,
    valid: true,
    pincode:"",
    file:"",
    show1: false,
    show4: false,
    is_admin:false,
    user_name: '',
    password: '',
    password_confirm: '',
    email: '',
    rfidcode: '',

    email_rules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],


    department: '',
    nameRules: [
      v => !!v || 'Name is required',
      v => (v && v.length <= 10) || 'Name must be less than 10 characters',
    ],



    select: null,
    itemss:[],
    checkbox: false,

    baseUrl:baseUrl,
    items: [
      {
        text: 'หน้าหลัก',
        disabled: false,
        href: '/',
      },
      {
        text: 'ข้อมูลความต้องการในการพัฒนา ',
        disabled: true,
        href: '/plan_requirement',
      },
    ],
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: 'รูปโปรไฟล์',
        align: 'start',
        sortable: false,
        value: 'image',
      },
      { text: 'ชื่อ', value: 'first_name' },
      { text: 'นามสกุล', value: 'last_name' },
      { text: 'แผนก', value: 'departments[0].title' },
      { text: 'RFID', value: 'rfidcode' },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
    desserts: [],
    editedIndex: -1,
    resultData:[],

    activePicker: null,
    date: null,
    menu: false,

    first_name:"",
    last_name:"",
    sex:"",
    course_select:1,
    cours_list:[
      {
        "label":"สังคมศึกษาศาสนาและวัฒนธรรม",
        "id":1
      },
      {
        "label":"ภาษาไทย",
        "id":2
      },
      {
        "label":"คอมพิวเตอร์ศึกษา",
        "id":3
      }
    ],
    people_select:[],
    list_develop:"",
    affiliation:"",
    date_start:"",
    date_end:"",
    checkbox_1:false,
    checkbox_2:false,
    checkbox_3:false,
    checkbox_4:false,
    checkbox_5:false,
    checkbox_6:false,
    checkbox_7:false,
    checkbox_8:false,
    checkbox_9:false,
    checkbox_10:false,
    checkbox_11:false,
    checkbox_12:false,
    checkbox_13:false,
    checkbox_other:false,
    checkbox_2_1:false,
    checkbox_2_2:false,
    checkbox_2_3:false,
    checkbox_2_4:false,
    checkbox_2_5:false,
    checkbox_2_6:false,
    checkbox_2_7:false,
    checkbox_2_8:false,
    checkbox_2_9:false,
    checkbox_2_10:false,
    checkbox_2_11:false,
    checkbox_2_12:false,
    checkbox_2_13:false,
    checkbox_2_14:false,
    checkbox_other_2:false,
    other_1:"",
    other_2:"",
    specific_details_develop:"",
    why_want_develop_course:"",
    what_do_you_expect:"",
    how_will_knowledge:"",


    people_type_select:[],
    people_list:[
      {
        "label":"ผศ.ดร.อรัญ ซุยกระเดื่อง",
        "id":1
      },
      {
        "label":"นายจักรพันธ์ แก้วกัณหา",
        "id":2
      },
      {
        "label":"นางสาววราภรณ์ สุภาเฮือง",
        "id":3
      }
    ],
    people_type:[
      {
        "label":"อาจารย์นิเทศ",
        "id":1
      },
      {
        "label":"ครูพี่เลี้ยง",
        "id":2
      },
      {
        "label":"นักศึกษา",
        "id":3
      }
    ],

    time: null,
    menu2: false,
    modal2: false,
  }),

  computed: {

  },
  watch: {
    menu (val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
  },

  created () {
    this.initialize()
    this.GetDepartment()
    this.GetMachines()
  },
  methods: {
    print_page(){
      window.open('./plan_print', '_blank');
    },
    save (date) {
      this.$refs.menu.save(date)
    },
    initialize () {

    },
    async GetMachines(){
      let machine = [];
      const { status , results } = await GetMachine();
      if(status === 200){

        results.data.forEach(function (result){
          console.log(result)
          machine.push({
            "label":result.title,
            "id":result.machine_number,
          })
        });
      }

      this.machine_item = machine;
      console.log(this.itemss)

    },
    validate () {
      console.log(this.file)
      if(this.$refs.form.validate()){
        this.UserRegister();
      }
    },
    reset () {
      this.$refs.form.reset()
      $('#image_preview').attr('src', '');
    },
    async UserRegister(){
      let Swal = this.$swal;

      let formData = new FormData();
      formData.append("first_name",this.first_name)
      formData.append("last_name",this.last_name)
      formData.append("user_name",this.user_name)
      formData.append("password",this.password)
      formData.append("email",this.email)
      formData.append("rfidcode",this.rfidcode)
      formData.append("pincode",this.pincode)
      formData.append("is_admin",this.is_admin ? 1 : 0)
      formData.append("department_id",this.department)
      formData.append("file",this.file)
      formData.append("machine_id",this.machine_id)


      const { status , results } = await UserRegister(formData,this.machine_id);
      if(status === 200){
        Swal({
          icon: 'success',
          title: 'Success',
        })
        this.$refs.form.reset()
        $('#image_preview').attr('src', '');
        console.log(results)
      }else{

        Swal({
          icon: results.icon,
          title: results.message,
        })

      }

    },

    async GetDepartment(){
      let department = [];
      const { status , results } = await GetDepartment();
      if(status === 200){

        results.data.forEach(function (result,index){
          console.log(index)
          department.push({
            "label":result.title,
            "id":result.department_id,
          })
        });
      }

      this.itemss = department;
      console.log(this.itemss)

    },
    Preview_image(e) {
      if (e) {
        this.file = e;
        $('#image_preview').attr('src', URL.createObjectURL(e)); // jQuery selector
      }
    },
  },
}


</script>

<style scoped>
.card_container{
  width: 100%;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 1px solid #EBEDF3;
  border-radius: 0.42rem;
}
.card_header{
  padding: 1.5rem 2rem;
  margin-bottom: 0;
  background-color: #ffffff;
  border-bottom: 1px solid #EBEDF3;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.form_card{
  padding:2rem;
}
.image_preview_container{
  display: flex;
  justify-content: center;
  align-items: center;
}
#image_preview{
  width: 20rem;
  text-align: center;
}
.custom_table{
  border-collapse: collapse;
}
.table_container{
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.list_check_box{
  display: flex;
  gap: 20px;
}
</style>
