<template>

  <v-container class="custom-container-login" >
    <div class="login_container" >
      <div class="login-layout" >
        <v-row class="login-form" >
          <v-col  xl="6" lg="6" md="6" sm="12" xs="12" >
            <div class="login-left-layout" >
              <font class="header-title" >ระบบฐานข้อมูลนักศึกษาฝึกประสบการณ์ <br> วิชาชีพครูหลักสูตร ครุศาสตรบัณฑิต</font>
              <img class="img-login" src="../assets/image/student_group.png" />
            </div>
          </v-col>
          <v-col xl="6" lg="6" md="6" sm="12" xs="12" >
            <div class="login-right-layout" >
              <img src="../assets/image/Logo.png" />
              <div class="form-control-custom" >
                <v-form
                    ref="form"
                    v-model="valid"
                    lazy-validation
                >
                  <v-text-field
                      v-model="email"
                      :rules="[v => !!v || 'กรุณากรอกรหัสนักศึกษา/อีเมล']"
                      label="รหัสนักศึกษา/อีเมล"
                      required
                      placeholder="รหัสนักศึกษา/อีเมล"
                  ></v-text-field>

                  <v-text-field
                      v-model="password"
                      :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                      :rules="[v => !!v || 'กรุณากรอกรหัสผ่าน']"
                      :type="show_password ? 'text' : 'password'"
                      label="รหัสผ่าน"
                      @click:append="show_password = !show_password"
                  ></v-text-field>
                  <div align="right" class="pt-1 pb-2" >
                    <div class="right-btn-link" >
                      <a href="/" >สมัครสมาชิก</a> /
                      <a href="/" >ลืมรหัสผ่าน</a>
                    </div>
                  </div>
                  <div align="center">
                    <v-btn
                        class="mr-4 login-btn"
                        @click="validate"
                    >
                      เข้าสู่ระบบ
                    </v-btn>
                    <font v-if="notfound" class="notfound" >ไม่พบชื่อผู้ใช้งาน</font>
                  </div>

                </v-form>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
  </v-container>


</template>
<script>
// @ is an alias to /src

//import $ from "jquery";



export default {
  name: 'Home',
  data: () => ({
    password: '',
    email: '',
    valid: true,
    notfound: false,
    show_password: false,
    rules: {
      required: value => !!value || 'กรอกรหัสผ่าน.',
    },
    email_rules: [
      v => !!v || 'อีเมลต้องไม่เป็นค่าว่าง',
      v => /.+@.+\..+/.test(v) || 'รูปแบบอีเมลไม่ถูกต้อง',
    ],
  }),

  methods:{
    validate () {
      this.notfound = false;
      if(this.$refs.form.validate()){
        this.Login();
      }
    },
    async Login(){
      this.notfound = false;
      const param = {
        "email":this.email,
        "password":this.password,
      }


      localStorage.setItem('user',JSON.stringify(param))
      window.location = "/"

      /*const { status , results,error } = await auth(param);
      console.log(error)
      if(results.code === 200){
        let Users = JSON.stringify(results.data.user);
        localStorage.setItem('user',Users)
        window.location = "/"
        console.log(status)
        console.log(results)
      }else{
        this.notfound = true;
        console.log(results)
      }*/
    },
    reset () {
      this.$refs.form.reset()
    },
    CheckLogin(){
      const UserData = localStorage.getItem('user');
      if(UserData){
        console.log("Islogin")
        //window.location = "/"
      }else{
        //console.log("Nologin")
      }
    }
  },
  mounted() {
    this.CheckLogin();
  }
}
</script>
<style scoped >
body{
  background: #E5E8F0;
}
.form_login{
  width: 50rem;
  height: auto;
  padding:3rem;
  background: #ffffff;
  border-radius: 0.5rem;
}
.login_container{
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  height: 100%;
  background: #E5E8F0;
  padding: 2rem;

}
.notfound{
  font-size: 1rem;
  color: red;
}
.login-layout{
  width: 100%;
  height: 100%;
  border-radius: 20px;
  overflow: hidden;
  border: 5px solid rgb(255 255 255 / 69%);
}
.v-text-field > .v-input__control > .v-input__slot > .v-text-field__slot {
  display: flex;
  flex: 1 1 auto;
  position: relative;
  background: whitesmoke;
}
</style>
