<template>
  <div>
    <div class="page_print_landscape">
      <div align="left" >
        1.5 ประวัติการเข้ารับการพัฒนาตนเอง ในรอบ 4 ปีที่ผ่านมา (ปี พ.ศ. 2562 - 2565
      </div><br>
      <table border="1" class="table_list_history" >
        <tr>
          <th rowspan="2" style="width: 5%" >ที่</th>
          <th rowspan="2" style="width: 20%" >รายการที่ได้รับการพัฒนา</th>
          <th rowspan="2" style="width: 20%" >สมรรถนะ ที่พัฒนา</th>
          <th colspan="2" style="width: 10%" >วัน เดือน ปี ในการพัฒนา</th>
          <th rowspan="2" style="width: 5%" >จำนวนชั่วโมงการพัฒนา</th>
          <th rowspan="2" style="width: 15%" >หน่วยงานที่ดำเนินการพัฒนา</th>
          <th rowspan="2" style="width: 15%" >หมายเหตุ</th>
        </tr>
        <tr>
          <th>เริ่มต้น</th>
          <th>สิ้นสุด</th>
        </tr>
        <tr class="row_data" >
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr class="row_data" >
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr class="row_data" >
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr class="row_data" >
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr class="row_data" >
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr class="row_data" >
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr class="row_data" >
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
        <tr class="row_data" >
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>

      </table>

    </div>





  </div>
</template>

<script>
import {GetDepartment, GetMachine, UserRegister} from "../../lib/controller";
import {baseUrl} from "../../const/api";
import $ from 'jquery'

export default {
  name: "Users",
  components: {},
  data: () => ({
    rules: [
      value => !value || value.size < 2000000 || 'Avatar size should be less than 2 MB!',
    ],
    machine_item: {},
    machine_id: 0,
    valid: true,
    pincode: "",
    file: "",
    show1: false,
    show4: false,
    is_admin: false,
    first_name: '',
    last_name: '',
    user_name: '',
    password: '',
    password_confirm: '',
    email: '',
    rfidcode: '',

    email_rules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],


    department: '',
    nameRules: [
      v => !!v || 'Name is required',
      v => (v && v.length <= 10) || 'Name must be less than 10 characters',
    ],


    select: null,
    itemss: [],
    checkbox: false,

    baseUrl: baseUrl,
    items: [
      {
        text: 'หน้าหลัก',
        disabled: false,
        href: '/',
      },
      {
        text: 'การสะท้อนการเรียนรู้ วงรอบที่ 1',
        disabled: true,
        href: '/aar_1',
      },
    ],
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: 'รูปโปรไฟล์',
        align: 'start',
        sortable: false,
        value: 'image',
      },
      {text: 'ชื่อ', value: 'first_name'},
      {text: 'นามสกุล', value: 'last_name'},
      {text: 'แผนก', value: 'departments[0].title'},
      {text: 'RFID', value: 'rfidcode'},
      {text: 'Actions', value: 'actions', sortable: false},
    ],
    desserts: [],
    editedIndex: -1,
    resultData: [],

    activePicker: null,
    date: null,
    menu: false,

    name: "",
    school: "",
    amphur: "",
    province: "",
    class_detail: "",
    student_amount: "",
    course_select: 1,
    people_amount: 0,
    plan_detail: "",
    activity_plan: "",
    work_sheet: "",
    plan_problem_fix: "",

    cours_list: [
      {
        "label": "สังคมศึกษาศาสนาและวัฒนธรรม",
        "id": 1
      },
      {
        "label": "ภาษาไทย",
        "id": 2
      },
      {
        "label": "คอมพิวเตอร์ศึกษา",
        "id": 3
      }
    ],
    people_select: [],
    people_type_select: [],
    people_list: [
      {
        "label": "ผศ.ดร.อรัญ ซุยกระเดื่อง",
        "id": 1
      },
      {
        "label": "นายจักรพันธ์ แก้วกัณหา",
        "id": 2
      },
      {
        "label": "นางสาววราภรณ์ สุภาเฮือง",
        "id": 3
      }
    ],
    people_type: [
      {
        "label": "อาจารย์นิเทศ",
        "id": 1
      },
      {
        "label": "ครูพี่เลี้ยง",
        "id": 2
      },
      {
        "label": "นักศึกษา",
        "id": 3
      }
    ],

    time: null,
    menu2: false,
    modal2: false,
  }),

  computed: {},
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
  },

  created() {
    this.initialize()
    this.GetDepartment()
    this.GetMachines()
  },
  methods: {
    save(date) {
      this.$refs.menu.save(date)
    },
    initialize() {

    },
    async GetMachines() {
      let machine = [];
      const {status, results} = await GetMachine();
      if (status === 200) {

        results.data.forEach(function (result) {
          console.log(result)
          machine.push({
            "label": result.title,
            "id": result.machine_number,
          })
        });
      }

      this.machine_item = machine;
      console.log(this.itemss)

    },
    validate() {
      console.log(this.file)
      if (this.$refs.form.validate()) {
        this.UserRegister();
      }
    },
    reset() {
      this.$refs.form.reset()
      $('#image_preview').attr('src', '');
    },
    async UserRegister() {
      let Swal = this.$swal;

      let formData = new FormData();
      formData.append("first_name", this.first_name)
      formData.append("last_name", this.last_name)
      formData.append("user_name", this.user_name)
      formData.append("password", this.password)
      formData.append("email", this.email)
      formData.append("rfidcode", this.rfidcode)
      formData.append("pincode", this.pincode)
      formData.append("is_admin", this.is_admin ? 1 : 0)
      formData.append("department_id", this.department)
      formData.append("file", this.file)
      formData.append("machine_id", this.machine_id)


      const {status, results} = await UserRegister(formData, this.machine_id);
      if (status === 200) {
        Swal({
          icon: 'success',
          title: 'Success',
        })
        this.$refs.form.reset()
        $('#image_preview').attr('src', '');
        console.log(results)
      } else {

        Swal({
          icon: results.icon,
          title: results.message,
        })

      }

    },

    async GetDepartment() {
      let department = [];
      const {status, results} = await GetDepartment();
      if (status === 200) {

        results.data.forEach(function (result, index) {
          console.log(index)
          department.push({
            "label": result.title,
            "id": result.department_id,
          })
        });
      }

      this.itemss = department;
      console.log(this.itemss)

    },
    Preview_image(e) {
      if (e) {
        this.file = e;
        $('#image_preview').attr('src', URL.createObjectURL(e)); // jQuery selector
      }
    },
  },
  mounted() {
    print()
  }
}


</script>

<style scoped>

@media screen{
  .page_print_landscape{
    display: none;
  }
}

@page {
  size: A4 landscape ;
}

/* Size in mm */
@page {
  size: 100mm 200mm ;
}

/* Size in inches */
@page {
  size: 4in 6in ;
}
</style>
