<template>
 <div></div>
</template>
<script>
// @ is an alias to /src

export default {
  name: 'Home',
  data: () => ({
    items: [
      {
        text: 'Home',
        disabled: false,
        href: '/',
      },
    ],
  }),
}
</script>
