var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-breadcrumbs',{attrs:{"items":_vm.items},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-breadcrumbs-item',{attrs:{"to":item.href,"disabled":item.disabled}},[_vm._v(" "+_vm._s(item.text.toUpperCase())+" ")])]}}])},[_c('div',{staticClass:"card_container"},[_c('div',{staticClass:"card_header"},[_c('div',[_vm._v("วุฒิการศึกษาที่สำเร็จการศึกษาตั้งแต่ระดับปริญญาตรีขึ้น")])]),_c('v-breadcrumbs',{attrs:{"items":_vm.items},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-breadcrumbs-item',{attrs:{"to":item.href,"disabled":item.disabled}},[_vm._v(" "+_vm._s(item.text.toUpperCase())+" ")])]}}])}),_c('div',{staticClass:"form_card"},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[[_c('v-file-input',{attrs:{"label":"ใบรับรองคุณวุฒิ","filled":"","prepend-icon":"mdi-camera"}})],_c('v-text-field',{attrs:{"rules":[function (v) { return !!v || 'กรุณากรอกของ'; }],"label":"ของ ","required":""},model:{value:(_vm.motto),callback:function ($$v) {_vm.motto=$$v},expression:"motto"}}),_c('v-row',[_c('v-col',{attrs:{"cols":"6","sm":"5"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"ให้นะวันที่","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"active-picker":_vm.activePicker},on:{"update:activePicker":function($event){_vm.activePicker=$event},"update:active-picker":function($event){_vm.activePicker=$event},"change":_vm.save},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1)],1),[_c('v-file-input',{attrs:{"label":"ใบรายงานผลการศึกษา","filled":"","prepend-icon":"mdi-camera"}})],_c('v-text-field',{attrs:{"rules":[function (v) { return !!v || 'กรุณากรอกของ'; }],"label":"ของ ","required":""},model:{value:(_vm.motto),callback:function ($$v) {_vm.motto=$$v},expression:"motto"}}),_c('v-row',[_c('v-col',{attrs:{"cols":"6","sm":"5"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"วันที่สำเร็จการศึกษา","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"active-picker":_vm.activePicker},on:{"update:activePicker":function($event){_vm.activePicker=$event},"update:active-picker":function($event){_vm.activePicker=$event},"change":_vm.save},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1)],1),_c('div',{staticStyle:{"margin-top":"2rem"},attrs:{"align":"center"}},[_c('v-btn',{staticClass:"mr-4",attrs:{"color":"success"},on:{"click":_vm.validate}},[_vm._v(" อัพเดท ")]),_c('v-btn',{staticClass:"mr-4",attrs:{"color":"error"},on:{"click":_vm.reset}},[_vm._v(" ล้างค่า ")])],1)],2)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }