import {API, instance, instance_service, SERVICE} from "../const/api";



export async function GetUser(params){
    const { status, data: results } = await instance.get(API.User,{params});
    return { status, results }
}


export async function GetUserSingle(id){
    const { status, data: results } = await instance.get(API.User+"/"+id,{});
    return { status, results }
}


export async function UserRegister(FormInput,machine_id){
    const { status, results } = await instance.post(API.User,FormInput).catch(function (error) {
        console.log(status)
        if (error.response) {
            console.log(error.response.data)
            return { status:error.response.status, results:error.response.data }
        }
    });

    await instance_service.get(SERVICE.CHECK+"/"+machine_id);
    return { status, results }
}

export async function UserUpdates(id,FormInput,machine_id){
    const { status, results } = await instance.patch(API.User+"/"+id,FormInput)
        .catch(function (error) {
        console.log(status)
            if (error.response) {
                console.log(error.response.data)
                return { status:error.response.status, results:error.response.data }
            }
        });

        await instance_service.get(SERVICE.UPDATEUser+"/"+machine_id+"/"+id);

        return { status, results }
}


export async function DeleteUser(id){
    const { status, data: results } = await instance.delete(API.User+"/"+id);
    return { status, results }
}

export async function DeleteProduct(id,machine){
    const { status, data: results } = await instance.delete(API.Product+"/"+id);
    await instance_service.get(SERVICE.DEL+"/"+machine+"/"+id,{});
    return { status, results }
}


export async function RestoreProduct(id,machine){
    const { status, data: results } = await instance.put(API.Product+"/restore/"+id);
    await instance_service.get(SERVICE.RESTOER+"/"+machine+"/"+id,{});
    return { status, results }
}


export async function GetDepartment(){
    const { status, data: results } = await instance.get(API.Department,{});
    return { status, results }
}

export async function AddDepartment(formData){
    const { status, data: results } = await instance.post(API.Department,formData);
    return { status, results }
}

export async function UpdateDepartment(id,formData){
    const { status, data: results } = await instance.patch(API.Department+"/"+id,formData);
    return { status, results }
}

export async function DeleteDepartment(id){
    const { status, data: results } = await instance.delete(API.Department+"/"+id);
    return { status, results }
}


export async function GetCategory(){
    const { status, data: results } = await instance.get(API.Category,{});
    return { status, results }
}

export async function AddCategory(formData){
    const { status, data: results } = await instance.post(API.Category,formData);
    return { status, results }
}

export async function UpdateCategory(id,formData,category){
    const { status, data: results } = await instance.patch(API.Category+"/"+id,formData);
    await instance_service.get(SERVICE.UPDATECategory+"/"+category);
    return { status, results }
}


export async function GetSubCategory(id){
    const { status, data: results } = await instance.get(API.ListSubcategory+"/"+id);
    return { status, results }
}

export async function SubCategory(formData){
    const { status, data: results } = await instance.post(API.Subcategory,formData);
    return { status, results }
}


export async function UpdateSubCategory(id,formData){
    const { status, data: results } = await instance.patch(API.Subcategory+"/"+id,formData);
    return { status, results }
}


export async function GetProduct(params){
    const { status, data: results } = await instance.get(API.Product,{params});
    return { status, results }
}

export async function GetProductAll(params){
    const { status, data: results } = await instance.get(API.Product+"/all",{params});
    return { status, results }
}


export async function GetProductAllDeactive(params){
    const { status, data: results } = await instance.get(API.Product+"/all_deactive",{params});
    return { status, results }
}

export async function AddProduct(FormInput,machine){
    const { status,  results } = await instance.post(API.Product,FormInput)
        .catch(function (error) {
        if (error.response) {
            return { status:error.response.status, results:error.response.data }
        }
    });

    await instance_service.get(SERVICE.CHECK+"/"+machine);

    return { status, results }
}

export async function UpdateProduct(id,machine,FormInput){
    const { status, data: results } = await instance.patch(API.Product+"/"+id,FormInput);
    await instance_service.get(SERVICE.UPDATE+"/"+machine+"/"+id);
    return { status, results }
}


export async function CheckProduct(id,machine){
    const { status, data: results } =  await instance_service.get(SERVICE.UPDATE+"/"+machine+"/"+id);
    return { status, results }
}


export async function SingleProduct(id){
    const { status, data: results } = await instance.get(API.Product+"/"+id);
    return { status, results }
}

export async function GetStock(id){
    const { status, data: results } = await instance.get(API.Stock+"/"+id);
    return { status, results }
}


export async function AddStock(formData,machine_id){
    const { status, data: results } = await instance.post(API.Stock,formData);
    await instance_service.get(SERVICE.CHECK+"/"+machine_id);
    return { status, results }
}

export async function AddMachine(formData){
    const { status, data: results } = await instance.post(API.Machine,formData);
    return { status, results }
}

export async function UpdateMachine(id,formData){
    const { status, data: results } = await instance.patch(API.Machine+"/"+id,formData);
    return { status, results }
}

export async function GetMachine(){
    const { status, data: results } = await instance.get(API.Machine);
    return { status, results }
}

export async function GetOrder(){
    const { status, data: results } = await instance.get(API.Order);
    return { status, results }
}

export async function GetOrderReport(params){
    console.log(params)
    const { status, data: results } = await instance.get(API.Order+"/report",{
        params
    });
    return { status, results }
}


export async function GetStockReport(params){
    const { status, data: results } = await instance.get(API.Product+"/report_stock",{
        params
    });
    return { status, results }
}

export async function GetVersion(params){
    const { status, data: results } = await instance.get("v",{
        params
    });
    return { status, results }
}



export async function GetVersionService(params){
    const { status, data: results } = await instance_service.get("v",{
        params
    });
    return { status, results }
}
