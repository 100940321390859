<template>
  <v-breadcrumbs :items="items">
    <template v-slot:item="{ item }">
      <v-breadcrumbs-item
          :to="item.href"
          :disabled="item.disabled"
      >
        {{ item.text.toUpperCase() }}
      </v-breadcrumbs-item>
    </template>
    <div class="card_container">
      <div class="card_header">
        <div>วุฒิการศึกษาที่สำเร็จการศึกษาตั้งแต่ระดับปริญญาตรีขึ้น</div>
      </div>

      <v-breadcrumbs :items="items">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item
              :to="item.href"
              :disabled="item.disabled"
          >
            {{ item.text.toUpperCase() }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
      <div class="form_card">
        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
        >


          <template>
            <v-file-input
                label="ใบรับรองคุณวุฒิ"
                filled
                prepend-icon="mdi-camera"
            ></v-file-input>
          </template>

          <v-text-field
              v-model="motto"
              :rules="[v => !!v || 'กรุณากรอกของ']"
              label="ของ "
              required
          ></v-text-field>

          <v-row>
            <v-col
                cols="6"
                sm="5"
            >
              <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="date"
                      label="ให้นะวันที่"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="date"
                    :active-picker.sync="activePicker"
                    @change="save"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>


          <template>
            <v-file-input
                label="ใบรายงานผลการศึกษา"
                filled
                prepend-icon="mdi-camera"
            ></v-file-input>
          </template>

          <v-text-field
              v-model="motto"
              :rules="[v => !!v || 'กรุณากรอกของ']"
              label="ของ "
              required
          ></v-text-field>

          <v-row>
            <v-col
                cols="6"
                sm="5"
            >
              <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="date"
                      label="วันที่สำเร็จการศึกษา"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="date"
                    :active-picker.sync="activePicker"
                    @change="save"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>



          <div align="center" style="margin-top: 2rem">
            <v-btn
                color="success"
                class="mr-4"
                @click="validate"
            >
              อัพเดท
            </v-btn>

            <v-btn
                color="error"
                class="mr-4"
                @click="reset"
            >
              ล้างค่า
            </v-btn>


          </div>

        </v-form>
      </div>

    </div>
  </v-breadcrumbs>


</template>

<script>
import {GetDepartment, GetMachine, UserRegister} from "../../lib/controller";
import {baseUrl} from "../../const/api";
import $ from 'jquery'

export default {
  name: "Users",
  data: () => ({
    rules: [
      value => !value || value.size < 2000000 || 'Avatar size should be less than 2 MB!',
    ],
    machine_item: {},
    machine_id: 0,
    valid: true,
    pincode: "",
    file: "",
    show1: false,
    show4: false,
    is_admin: false,
    first_name: '',
    last_name: '',
    user_name: '',
    password: '',
    password_confirm: '',
    rfidcode: '',

    email_rules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],


    department: '',
    nameRules: [
      v => !!v || 'Name is required',
      v => (v && v.length <= 10) || 'Name must be less than 10 characters',
    ],


    select: null,
    itemss: [],
    checkbox: false,

    baseUrl: baseUrl,
    items: [
      {
        text: 'หน้าหลัก',
        disabled: false,
        href: '/',
      },
      {
        text: 'วุฒิการศึกษาที่สำเร็จการศึกษาตั้งแต่ระดับปริญญาตรีขึ้น',
        disabled: true,
        href: '/profile',
      },
    ],
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: 'รูปโปรไฟล์',
        align: 'start',
        sortable: false,
        value: 'image',
      },
      {text: 'ชื่อ', value: 'first_name'},
      {text: 'นามสกุล', value: 'last_name'},
      {text: 'แผนก', value: 'departments[0].title'},
      {text: 'RFID', value: 'rfidcode'},
      {text: 'Actions', value: 'actions', sortable: false},
    ],
    desserts: [],
    editedIndex: -1,
    resultData: [],

    activePicker: null,
    date: null,
    menu: false,

    name: "",
    school: "",
    address: "",
    moo: "",
    tumbon: "",
    amphur: "",
    province: "",
    postcode: "",
    class_detail: "",
    student_amount: "",
    course_select: 1,
    people_amount: 0,
    introduction: "",
    nickname: "",
    nation: "",
    origin: "",
    religion: "",
    tel: "",
    email: "",
    address_current: "",
    moo_current: "",
    tumbon_current: "",
    amphur_current: "",
    province_current: "",
    postcode_current: "",
    motto: "",
    goal_life: "",
    hobby: "",
    grad: "",
    grad1: "",
    grad2: "",
    degree_1: "",
    degree_2: "",





    cours_list: [
      {
        "label": "สังคมศึกษาศาสนาและวัฒนธรรม",
        "id": 1
      },
      {
        "label": "ภาษาไทย",
        "id": 2
      },
      {
        "label": "คอมพิวเตอร์ศึกษา",
        "id": 3
      }
    ],
    people_select: [],
    people_type_select: [],
    people_list: [
      {
        "label": "ผศ.ดร.อรัญ ซุยกระเดื่อง",
        "id": 1
      },
      {
        "label": "นายจักรพันธ์ แก้วกัณหา",
        "id": 2
      },
      {
        "label": "นางสาววราภรณ์ สุภาเฮือง",
        "id": 3
      }
    ],
    people_type: [
      {
        "label": "อาจารย์นิเทศ",
        "id": 1
      },
      {
        "label": "ครูพี่เลี้ยง",
        "id": 2
      },
      {
        "label": "นักศึกษา",
        "id": 3
      }
    ],

    time: null,
    menu2: false,
    modal2: false,
  }),

  computed: {},
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
  },

  created() {
    this.initialize()
    this.GetDepartment()
    this.GetMachines()
  },
  methods: {
    save(date) {
      this.$refs.menu.save(date)
    },
    initialize() {

    },
    async GetMachines() {
      let machine = [];
      const {status, results} = await GetMachine();
      if (status === 200) {

        results.data.forEach(function (result) {
          console.log(result)
          machine.push({
            "label": result.title,
            "id": result.machine_number,
          })
        });
      }

      this.machine_item = machine;
      console.log(this.itemss)

    },
    validate() {
      console.log(this.file)
      if (this.$refs.form.validate()) {
        this.UserRegister();
      }
    },
    reset() {
      this.$refs.form.reset()
      $('#image_preview').attr('src', '');
    },
    async UserRegister() {
      let Swal = this.$swal;

      let formData = new FormData();
      formData.append("first_name", this.first_name)
      formData.append("last_name", this.last_name)
      formData.append("user_name", this.user_name)
      formData.append("password", this.password)
      formData.append("email", this.email)
      formData.append("rfidcode", this.rfidcode)
      formData.append("pincode", this.pincode)
      formData.append("is_admin", this.is_admin ? 1 : 0)
      formData.append("department_id", this.department)
      formData.append("file", this.file)
      formData.append("machine_id", this.machine_id)


      const {status, results} = await UserRegister(formData, this.machine_id);
      if (status === 200) {
        Swal({
          icon: 'success',
          title: 'Success',
        })
        this.$refs.form.reset()
        $('#image_preview').attr('src', '');
        console.log(results)
      } else {

        Swal({
          icon: results.icon,
          title: results.message,
        })

      }

    },

    async GetDepartment() {
      let department = [];
      const {status, results} = await GetDepartment();
      if (status === 200) {

        results.data.forEach(function (result, index) {
          console.log(index)
          department.push({
            "label": result.title,
            "id": result.department_id,
          })
        });
      }

      this.itemss = department;
      console.log(this.itemss)

    },
    Preview_image(e) {
      if (e) {
        this.file = e;
        $('#image_preview').attr('src', URL.createObjectURL(e)); // jQuery selector
      }
    },
  },
}


</script>

<style scoped>
.card_container {
  width: 100%;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 1px solid #EBEDF3;
  border-radius: 0.42rem;
}

.card_header {
  padding: 1.5rem 2rem;
  margin-bottom: 0;
  background-color: #ffffff;
  border-bottom: 1px solid #EBEDF3;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form_card {
  padding: 2rem;
}

.image_preview_container {
  display: flex;
  justify-content: center;
  align-items: center;
}

#image_preview {
  width: 20rem;
  text-align: center;
}

.custom_table {
  border-collapse: collapse;
}

.table_container {
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}
</style>
